import React from "react";
import ScaleRangeBar from "../../ScaleRangeBar/ScaleRangeBar";
import SwatchSelector from "../../SwatchSelector/SwatchSelector";

const ControlPanelScaleTab = () => {
  return (
    <div>
      <ScaleRangeBar />
    </div>
  );
};

export default ControlPanelScaleTab;
