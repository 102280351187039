import { styled } from "@stitches/react";
import React, { FC } from "react";
// import logo from "../../assets/logo2.svg";
const StyledImage = styled("img", {
  flex: "none",
});
const Logo: FC<{
  width?: string;
  height?: string;

  top?: string;
  left?: string;
  logo: any;
}> = ({
  width = "158px",
  height = "24px",
  top = "0em",
  left = "0em",
  logo,
}) => {
  return (
    <StyledImage
      style={{
        width: width,
        height: height,
        position: "relative",
        left,
        top,
      }}
      src={logo}
      alt="logo"
    />
  );
};

export default Logo;
