import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type AddColorPaletteOutput = {
  __typename?: 'AddColorPaletteOutput';
  data?: Maybe<ColorPalettes>;
  message: Scalars['String'];
  status: Scalars['String'];
};

export type Assets = {
  __typename?: 'Assets';
  _count?: Maybe<AssetsCount>;
  active: Scalars['Boolean'];
  colors: Array<Scalars['String']>;
  created_at: Scalars['DateTime'];
  default_repeats: Scalars['Int'];
  deleted: Scalars['Boolean'];
  height: Scalars['Float'];
  high_resolution_layers: Array<Scalars['String']>;
  id: Scalars['String'];
  lockedLayers: Array<Scalars['Boolean']>;
  low_resolution_layers: Array<Scalars['String']>;
  maxRepeats: Scalars['Float'];
  minRepeats: Scalars['Float'];
  product_id: Scalars['String'];
  product_name: Scalars['String'];
  updated_at: Scalars['DateTime'];
  width: Scalars['Float'];
};

export type AssetsCount = {
  __typename?: 'AssetsCount';
  AssetsVariants: Scalars['Int'];
};

export type AssetsVariants = {
  __typename?: 'AssetsVariants';
  assetsId: Scalars['String'];
  colors: Array<Scalars['String']>;
  created_at: Scalars['DateTime'];
  id: Scalars['String'];
  updated_at: Scalars['DateTime'];
  variantName: Scalars['String'];
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type ColorGroups = {
  __typename?: 'ColorGroups';
  _count?: Maybe<ColorGroupsCount>;
  active: Scalars['Boolean'];
  color_palette_ids: Array<Scalars['String']>;
  created_at: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  group_name: Scalars['String'];
  id: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type ColorGroupsCount = {
  __typename?: 'ColorGroupsCount';
  color_palettes: Scalars['Int'];
};

export type ColorGroupsCreateNestedManyWithoutColor_PalettesInput = {
  connect?: InputMaybe<Array<ColorGroupsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ColorGroupsCreateOrConnectWithoutColor_PalettesInput>>;
  create?: InputMaybe<Array<ColorGroupsCreateWithoutColor_PalettesInput>>;
};

export type ColorGroupsCreateOrConnectWithoutColor_PalettesInput = {
  create: ColorGroupsCreateWithoutColor_PalettesInput;
  where: ColorGroupsWhereUniqueInput;
};

export type ColorGroupsCreateWithoutColor_PalettesInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  color_palette_ids?: InputMaybe<ColorGroupsCreatecolor_Palette_IdsInput>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  group_name: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export type ColorGroupsCreatecolor_Palette_IdsInput = {
  set: Array<Scalars['String']>;
};

export type ColorGroupsScalarWhereInput = {
  AND?: InputMaybe<Array<ColorGroupsScalarWhereInput>>;
  NOT?: InputMaybe<Array<ColorGroupsScalarWhereInput>>;
  OR?: InputMaybe<Array<ColorGroupsScalarWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  color_palette_ids?: InputMaybe<StringNullableListFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<BoolFilter>;
  group_name?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type ColorGroupsUpdateManyMutationInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  color_palette_ids?: InputMaybe<ColorGroupsUpdatecolor_Palette_IdsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group_name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ColorGroupsUpdateManyWithWhereWithoutColor_PalettesInput = {
  data: ColorGroupsUpdateManyMutationInput;
  where: ColorGroupsScalarWhereInput;
};

export type ColorGroupsUpdateManyWithoutColor_PalettesInput = {
  connect?: InputMaybe<Array<ColorGroupsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ColorGroupsCreateOrConnectWithoutColor_PalettesInput>>;
  create?: InputMaybe<Array<ColorGroupsCreateWithoutColor_PalettesInput>>;
  delete?: InputMaybe<Array<ColorGroupsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ColorGroupsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ColorGroupsWhereUniqueInput>>;
  set?: InputMaybe<Array<ColorGroupsWhereUniqueInput>>;
  update?: InputMaybe<Array<ColorGroupsUpdateWithWhereUniqueWithoutColor_PalettesInput>>;
  updateMany?: InputMaybe<Array<ColorGroupsUpdateManyWithWhereWithoutColor_PalettesInput>>;
  upsert?: InputMaybe<Array<ColorGroupsUpsertWithWhereUniqueWithoutColor_PalettesInput>>;
};

export type ColorGroupsUpdateWithWhereUniqueWithoutColor_PalettesInput = {
  data: ColorGroupsUpdateWithoutColor_PalettesInput;
  where: ColorGroupsWhereUniqueInput;
};

export type ColorGroupsUpdateWithoutColor_PalettesInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  color_palette_ids?: InputMaybe<ColorGroupsUpdatecolor_Palette_IdsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group_name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ColorGroupsUpdatecolor_Palette_IdsInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ColorGroupsUpsertWithWhereUniqueWithoutColor_PalettesInput = {
  create: ColorGroupsCreateWithoutColor_PalettesInput;
  update: ColorGroupsUpdateWithoutColor_PalettesInput;
  where: ColorGroupsWhereUniqueInput;
};

export type ColorGroupsWhereUniqueInput = {
  group_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type ColorPalettes = {
  __typename?: 'ColorPalettes';
  _count?: Maybe<ColorPalettesCount>;
  active: Scalars['Boolean'];
  color_group_ids: Array<Scalars['String']>;
  color_palette_name: Scalars['String'];
  colors: Array<Scalars['String']>;
  created_at: Scalars['DateTime'];
  created_by: Scalars['String'];
  deleted: Scalars['Boolean'];
  id: Scalars['String'];
  updated_at: Scalars['DateTime'];
  user_type: UserType;
};

export type ColorPalettesCount = {
  __typename?: 'ColorPalettesCount';
  color_groups: Scalars['Int'];
};

export type ColorPalettesCreateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  color_group_ids?: InputMaybe<ColorPalettesCreatecolor_Group_IdsInput>;
  color_groups?: InputMaybe<ColorGroupsCreateNestedManyWithoutColor_PalettesInput>;
  color_palette_name: Scalars['String'];
  colors?: InputMaybe<ColorPalettesCreatecolorsInput>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  created_by?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  user_type?: InputMaybe<UserType>;
};

export type ColorPalettesCreatecolor_Group_IdsInput = {
  set: Array<Scalars['String']>;
};

export type ColorPalettesCreatecolorsInput = {
  set: Array<Scalars['String']>;
};

export type ColorPalettesUpdateInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  color_group_ids?: InputMaybe<ColorPalettesUpdatecolor_Group_IdsInput>;
  color_groups?: InputMaybe<ColorGroupsUpdateManyWithoutColor_PalettesInput>;
  color_palette_name?: InputMaybe<StringFieldUpdateOperationsInput>;
  colors?: InputMaybe<ColorPalettesUpdatecolorsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<StringFieldUpdateOperationsInput>;
  deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_type?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
};

export type ColorPalettesUpdatecolor_Group_IdsInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ColorPalettesUpdatecolorsInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ColorPalettesWhereUniqueInput = {
  color_palette_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DeleteColorPaletteOutput = {
  __typename?: 'DeleteColorPaletteOutput';
  data?: Maybe<ColorPalettes>;
  message: Scalars['String'];
  status: Scalars['String'];
};

export type EnumUserTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<UserType>;
};

export type GetAssetOutput = {
  __typename?: 'GetAssetOutput';
  data?: Maybe<Assets>;
  message: Scalars['String'];
  status: Scalars['String'];
};

export type GetColorPaletteGroupListOutput = {
  __typename?: 'GetColorPaletteGroupListOutput';
  data?: Maybe<Array<ColorGroups>>;
  message: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};

export type GetPublicColorPaletteListOutput = {
  __typename?: 'GetPublicColorPaletteListOutput';
  data?: Maybe<Array<ColorPalettes>>;
  message: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};

export type GetRecolorAssetsOutput = {
  __typename?: 'GetRecolorAssetsOutput';
  data?: Maybe<RecoloredAssets>;
  message: Scalars['String'];
  status: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  deleteUserColorPalette: DeleteColorPaletteOutput;
  saveUserColorPalette: AddColorPaletteOutput;
  updateRecolorAssets: UpdateRecolorAssetsOutput;
  updateUserColorPalette: UpdateColorPaletteOutput;
};


export type MutationDeleteUserColorPaletteArgs = {
  where: ColorPalettesWhereUniqueInput;
};


export type MutationSaveUserColorPaletteArgs = {
  data: ColorPalettesCreateInput;
};


export type MutationUpdateRecolorAssetsArgs = {
  data: UpdateRecoloredAssetsInputArgs;
  where: RecoloredAssetsWhereUniqueInput;
};


export type MutationUpdateUserColorPaletteArgs = {
  data: ColorPalettesUpdateInput;
  where: ColorPalettesWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  getAssetDetailByProductId: GetAssetOutput;
  getAssetsVariants: Array<AssetsVariants>;
  getPublicColorPaletteGroups: GetColorPaletteGroupListOutput;
  getPublicColorPalettes: GetPublicColorPaletteListOutput;
  getRecoloredAssets: GetRecolorAssetsOutput;
  secureAPIService: Scalars['String'];
};


export type QueryGetAssetDetailByProductIdArgs = {
  product_id: Scalars['String'];
};


export type QueryGetAssetsVariantsArgs = {
  product_id: Scalars['String'];
};


export type QueryGetRecoloredAssetsArgs = {
  design_name?: InputMaybe<Scalars['String']>;
  isNewRecolorAsset: Scalars['Boolean'];
  product_id: Scalars['String'];
  recolor_id?: InputMaybe<Scalars['String']>;
  variantId: Scalars['String'];
};


export type QuerySecureApiServiceArgs = {
  data?: InputMaybe<Scalars['String']>;
  method?: InputMaybe<Scalars['String']>;
  route: Scalars['String'];
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RecoloredAssets = {
  __typename?: 'RecoloredAssets';
  asset_id: Scalars['String'];
  created_at: Scalars['DateTime'];
  design_name?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  new_colors: Array<Scalars['String']>;
  repeats: Scalars['Float'];
  session_id: Scalars['String'];
  updated_at: Scalars['DateTime'];
  variantId: Scalars['String'];
};

export type RecoloredAssetsOutput = {
  __typename?: 'RecoloredAssetsOutput';
  thumbnailURL?: Maybe<Scalars['String']>;
  updatedAsset?: Maybe<RecoloredAssets>;
};

export type RecoloredAssetsSession_IdAsset_IdIdCompoundUniqueInput = {
  asset_id: Scalars['String'];
  id: Scalars['String'];
  session_id: Scalars['String'];
};

export type RecoloredAssetsWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  session_id_asset_id_id?: InputMaybe<RecoloredAssetsSession_IdAsset_IdIdCompoundUniqueInput>;
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']>>;
  has?: InputMaybe<Scalars['String']>;
  hasEvery?: InputMaybe<Array<Scalars['String']>>;
  hasSome?: InputMaybe<Array<Scalars['String']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateColorPaletteOutput = {
  __typename?: 'UpdateColorPaletteOutput';
  data?: Maybe<ColorPalettes>;
  message: Scalars['String'];
  status: Scalars['String'];
};

export type UpdateRecolorAssetsOutput = {
  __typename?: 'UpdateRecolorAssetsOutput';
  data?: Maybe<RecoloredAssetsOutput>;
  message: Scalars['String'];
  status: Scalars['String'];
};

export type UpdateRecoloredAssetsInputArgs = {
  asset_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  design_name?: InputMaybe<Scalars['String']>;
  new_colors: Array<Scalars['String']>;
  repeats: Scalars['Float'];
  session_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export enum UserType {
  Admin = 'Admin',
  User = 'User'
}

export type GetAssetDetailByProductIdQueryVariables = Exact<{
  productId: Scalars['String'];
}>;


export type GetAssetDetailByProductIdQuery = { __typename?: 'Query', getAssetDetailByProductId: { __typename?: 'GetAssetOutput', message: string, status: string, data?: { __typename?: 'Assets', id: string, product_id: string, product_name: string, width: number, height: number, minRepeats: number, maxRepeats: number, default_repeats: number, low_resolution_layers: Array<string>, lockedLayers: Array<boolean>, colors: Array<string>, created_at: any, updated_at: any, active: boolean, deleted: boolean } | null }, getAssetsVariants: Array<{ __typename?: 'AssetsVariants', id: string, assetsId: string, variantName: string, colors: Array<string>, created_at: any, updated_at: any }> };

export type SaveUserColorPaletteMutationVariables = Exact<{
  data: ColorPalettesCreateInput;
}>;


export type SaveUserColorPaletteMutation = { __typename?: 'Mutation', saveUserColorPalette: { __typename?: 'AddColorPaletteOutput', message: string, status: string, data?: { __typename?: 'ColorPalettes', id: string, color_palette_name: string, colors: Array<string>, color_group_ids: Array<string> } | null } };

export type GetPublicColorPaletteQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPublicColorPaletteQuery = { __typename?: 'Query', getPublicColorPaletteGroups: { __typename?: 'GetColorPaletteGroupListOutput', message: string, status?: string | null, data?: Array<{ __typename?: 'ColorGroups', id: string, group_name: string, color_palette_ids: Array<string>, created_at: any, updated_at: any }> | null }, getPublicColorPalettes: { __typename?: 'GetPublicColorPaletteListOutput', message: string, status?: string | null, data?: Array<{ __typename?: 'ColorPalettes', id: string, color_palette_name: string, colors: Array<string>, color_group_ids: Array<string>, user_type: UserType, active: boolean }> | null } };

export type DeleteUserColorPaletteMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteUserColorPaletteMutation = { __typename?: 'Mutation', deleteUserColorPalette: { __typename?: 'DeleteColorPaletteOutput', message: string, status: string } };

export type UpdateUserColorPaletteNameMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
}>;


export type UpdateUserColorPaletteNameMutation = { __typename?: 'Mutation', updateUserColorPalette: { __typename?: 'UpdateColorPaletteOutput', message: string, status: string } };

export type GetRecoloredAssetsQueryVariables = Exact<{
  productId: Scalars['String'];
  isNewRecolorAsset: Scalars['Boolean'];
  recolorId?: InputMaybe<Scalars['String']>;
  variantId: Scalars['String'];
}>;


export type GetRecoloredAssetsQuery = { __typename?: 'Query', getRecoloredAssets: { __typename?: 'GetRecolorAssetsOutput', message: string, status: string, data?: { __typename?: 'RecoloredAssets', id: string, session_id: string, new_colors: Array<string>, asset_id: string, repeats: number, created_at: any, updated_at: any, design_name?: string | null } | null } };

export type UpdateRecolorAssetsMutationVariables = Exact<{
  data: UpdateRecoloredAssetsInputArgs;
  where: RecoloredAssetsWhereUniqueInput;
}>;


export type UpdateRecolorAssetsMutation = { __typename?: 'Mutation', updateRecolorAssets: { __typename?: 'UpdateRecolorAssetsOutput', message: string, status: string, data?: { __typename?: 'RecoloredAssetsOutput', thumbnailURL?: string | null, updatedAsset?: { __typename?: 'RecoloredAssets', id: string, session_id: string, new_colors: Array<string>, asset_id: string, repeats: number, created_at: any, updated_at: any, design_name?: string | null } | null } | null } };

export type SecureApiServiceQueryVariables = Exact<{
  route: Scalars['String'];
  data?: InputMaybe<Scalars['String']>;
  method?: InputMaybe<Scalars['String']>;
}>;


export type SecureApiServiceQuery = { __typename?: 'Query', secureAPIService: string };


export const GetAssetDetailByProductIdDocument = gql`
    query getAssetDetailByProductId($productId: String!) {
  getAssetDetailByProductId(product_id: $productId) {
    message
    status
    data {
      id
      product_id
      product_name
      width
      height
      minRepeats
      maxRepeats
      default_repeats
      low_resolution_layers
      lockedLayers
      colors
      created_at
      updated_at
      active
      deleted
    }
  }
  getAssetsVariants(product_id: $productId) {
    id
    assetsId
    variantName
    colors
    created_at
    updated_at
  }
}
    `;

/**
 * __useGetAssetDetailByProductIdQuery__
 *
 * To run a query within a React component, call `useGetAssetDetailByProductIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetDetailByProductIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetDetailByProductIdQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetAssetDetailByProductIdQuery(baseOptions: Apollo.QueryHookOptions<GetAssetDetailByProductIdQuery, GetAssetDetailByProductIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetDetailByProductIdQuery, GetAssetDetailByProductIdQueryVariables>(GetAssetDetailByProductIdDocument, options);
      }
export function useGetAssetDetailByProductIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetDetailByProductIdQuery, GetAssetDetailByProductIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetDetailByProductIdQuery, GetAssetDetailByProductIdQueryVariables>(GetAssetDetailByProductIdDocument, options);
        }
export type GetAssetDetailByProductIdQueryHookResult = ReturnType<typeof useGetAssetDetailByProductIdQuery>;
export type GetAssetDetailByProductIdLazyQueryHookResult = ReturnType<typeof useGetAssetDetailByProductIdLazyQuery>;
export type GetAssetDetailByProductIdQueryResult = Apollo.QueryResult<GetAssetDetailByProductIdQuery, GetAssetDetailByProductIdQueryVariables>;
export const SaveUserColorPaletteDocument = gql`
    mutation saveUserColorPalette($data: ColorPalettesCreateInput!) {
  saveUserColorPalette(data: $data) {
    message
    status
    data {
      id
      color_palette_name
      colors
      color_group_ids
    }
  }
}
    `;
export type SaveUserColorPaletteMutationFn = Apollo.MutationFunction<SaveUserColorPaletteMutation, SaveUserColorPaletteMutationVariables>;

/**
 * __useSaveUserColorPaletteMutation__
 *
 * To run a mutation, you first call `useSaveUserColorPaletteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserColorPaletteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserColorPaletteMutation, { data, loading, error }] = useSaveUserColorPaletteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveUserColorPaletteMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserColorPaletteMutation, SaveUserColorPaletteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUserColorPaletteMutation, SaveUserColorPaletteMutationVariables>(SaveUserColorPaletteDocument, options);
      }
export type SaveUserColorPaletteMutationHookResult = ReturnType<typeof useSaveUserColorPaletteMutation>;
export type SaveUserColorPaletteMutationResult = Apollo.MutationResult<SaveUserColorPaletteMutation>;
export type SaveUserColorPaletteMutationOptions = Apollo.BaseMutationOptions<SaveUserColorPaletteMutation, SaveUserColorPaletteMutationVariables>;
export const GetPublicColorPaletteDocument = gql`
    query getPublicColorPalette {
  getPublicColorPaletteGroups {
    message
    data {
      id
      group_name
      color_palette_ids
      created_at
      updated_at
    }
    status
  }
  getPublicColorPalettes {
    message
    status
    data {
      id
      color_palette_name
      colors
      color_group_ids
      user_type
      active
    }
  }
}
    `;

/**
 * __useGetPublicColorPaletteQuery__
 *
 * To run a query within a React component, call `useGetPublicColorPaletteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicColorPaletteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicColorPaletteQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPublicColorPaletteQuery(baseOptions?: Apollo.QueryHookOptions<GetPublicColorPaletteQuery, GetPublicColorPaletteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublicColorPaletteQuery, GetPublicColorPaletteQueryVariables>(GetPublicColorPaletteDocument, options);
      }
export function useGetPublicColorPaletteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicColorPaletteQuery, GetPublicColorPaletteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublicColorPaletteQuery, GetPublicColorPaletteQueryVariables>(GetPublicColorPaletteDocument, options);
        }
export type GetPublicColorPaletteQueryHookResult = ReturnType<typeof useGetPublicColorPaletteQuery>;
export type GetPublicColorPaletteLazyQueryHookResult = ReturnType<typeof useGetPublicColorPaletteLazyQuery>;
export type GetPublicColorPaletteQueryResult = Apollo.QueryResult<GetPublicColorPaletteQuery, GetPublicColorPaletteQueryVariables>;
export const DeleteUserColorPaletteDocument = gql`
    mutation deleteUserColorPalette($id: String!) {
  deleteUserColorPalette(where: {id: $id}) {
    message
    status
  }
}
    `;
export type DeleteUserColorPaletteMutationFn = Apollo.MutationFunction<DeleteUserColorPaletteMutation, DeleteUserColorPaletteMutationVariables>;

/**
 * __useDeleteUserColorPaletteMutation__
 *
 * To run a mutation, you first call `useDeleteUserColorPaletteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserColorPaletteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserColorPaletteMutation, { data, loading, error }] = useDeleteUserColorPaletteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserColorPaletteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserColorPaletteMutation, DeleteUserColorPaletteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserColorPaletteMutation, DeleteUserColorPaletteMutationVariables>(DeleteUserColorPaletteDocument, options);
      }
export type DeleteUserColorPaletteMutationHookResult = ReturnType<typeof useDeleteUserColorPaletteMutation>;
export type DeleteUserColorPaletteMutationResult = Apollo.MutationResult<DeleteUserColorPaletteMutation>;
export type DeleteUserColorPaletteMutationOptions = Apollo.BaseMutationOptions<DeleteUserColorPaletteMutation, DeleteUserColorPaletteMutationVariables>;
export const UpdateUserColorPaletteNameDocument = gql`
    mutation updateUserColorPaletteName($id: String!, $name: String!) {
  updateUserColorPalette(
    where: {id: $id}
    data: {color_palette_name: {set: $name}}
  ) {
    message
    status
  }
}
    `;
export type UpdateUserColorPaletteNameMutationFn = Apollo.MutationFunction<UpdateUserColorPaletteNameMutation, UpdateUserColorPaletteNameMutationVariables>;

/**
 * __useUpdateUserColorPaletteNameMutation__
 *
 * To run a mutation, you first call `useUpdateUserColorPaletteNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserColorPaletteNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserColorPaletteNameMutation, { data, loading, error }] = useUpdateUserColorPaletteNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateUserColorPaletteNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserColorPaletteNameMutation, UpdateUserColorPaletteNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserColorPaletteNameMutation, UpdateUserColorPaletteNameMutationVariables>(UpdateUserColorPaletteNameDocument, options);
      }
export type UpdateUserColorPaletteNameMutationHookResult = ReturnType<typeof useUpdateUserColorPaletteNameMutation>;
export type UpdateUserColorPaletteNameMutationResult = Apollo.MutationResult<UpdateUserColorPaletteNameMutation>;
export type UpdateUserColorPaletteNameMutationOptions = Apollo.BaseMutationOptions<UpdateUserColorPaletteNameMutation, UpdateUserColorPaletteNameMutationVariables>;
export const GetRecoloredAssetsDocument = gql`
    query getRecoloredAssets($productId: String!, $isNewRecolorAsset: Boolean!, $recolorId: String, $variantId: String!) {
  getRecoloredAssets(
    product_id: $productId
    isNewRecolorAsset: $isNewRecolorAsset
    recolor_id: $recolorId
    variantId: $variantId
  ) {
    message
    status
    data {
      id
      session_id
      new_colors
      asset_id
      repeats
      created_at
      updated_at
      design_name
    }
  }
}
    `;

/**
 * __useGetRecoloredAssetsQuery__
 *
 * To run a query within a React component, call `useGetRecoloredAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecoloredAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecoloredAssetsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      isNewRecolorAsset: // value for 'isNewRecolorAsset'
 *      recolorId: // value for 'recolorId'
 *      variantId: // value for 'variantId'
 *   },
 * });
 */
export function useGetRecoloredAssetsQuery(baseOptions: Apollo.QueryHookOptions<GetRecoloredAssetsQuery, GetRecoloredAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecoloredAssetsQuery, GetRecoloredAssetsQueryVariables>(GetRecoloredAssetsDocument, options);
      }
export function useGetRecoloredAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecoloredAssetsQuery, GetRecoloredAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecoloredAssetsQuery, GetRecoloredAssetsQueryVariables>(GetRecoloredAssetsDocument, options);
        }
export type GetRecoloredAssetsQueryHookResult = ReturnType<typeof useGetRecoloredAssetsQuery>;
export type GetRecoloredAssetsLazyQueryHookResult = ReturnType<typeof useGetRecoloredAssetsLazyQuery>;
export type GetRecoloredAssetsQueryResult = Apollo.QueryResult<GetRecoloredAssetsQuery, GetRecoloredAssetsQueryVariables>;
export const UpdateRecolorAssetsDocument = gql`
    mutation updateRecolorAssets($data: UpdateRecoloredAssetsInputArgs!, $where: RecoloredAssetsWhereUniqueInput!) {
  updateRecolorAssets(data: $data, where: $where) {
    message
    status
    data {
      updatedAsset {
        id
        session_id
        new_colors
        asset_id
        repeats
        created_at
        updated_at
        design_name
      }
      thumbnailURL
    }
  }
}
    `;
export type UpdateRecolorAssetsMutationFn = Apollo.MutationFunction<UpdateRecolorAssetsMutation, UpdateRecolorAssetsMutationVariables>;

/**
 * __useUpdateRecolorAssetsMutation__
 *
 * To run a mutation, you first call `useUpdateRecolorAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecolorAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecolorAssetsMutation, { data, loading, error }] = useUpdateRecolorAssetsMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateRecolorAssetsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecolorAssetsMutation, UpdateRecolorAssetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecolorAssetsMutation, UpdateRecolorAssetsMutationVariables>(UpdateRecolorAssetsDocument, options);
      }
export type UpdateRecolorAssetsMutationHookResult = ReturnType<typeof useUpdateRecolorAssetsMutation>;
export type UpdateRecolorAssetsMutationResult = Apollo.MutationResult<UpdateRecolorAssetsMutation>;
export type UpdateRecolorAssetsMutationOptions = Apollo.BaseMutationOptions<UpdateRecolorAssetsMutation, UpdateRecolorAssetsMutationVariables>;
export const SecureApiServiceDocument = gql`
    query secureAPIService($route: String!, $data: String, $method: String) {
  secureAPIService(route: $route, data: $data, method: $method)
}
    `;

/**
 * __useSecureApiServiceQuery__
 *
 * To run a query within a React component, call `useSecureApiServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecureApiServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecureApiServiceQuery({
 *   variables: {
 *      route: // value for 'route'
 *      data: // value for 'data'
 *      method: // value for 'method'
 *   },
 * });
 */
export function useSecureApiServiceQuery(baseOptions: Apollo.QueryHookOptions<SecureApiServiceQuery, SecureApiServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SecureApiServiceQuery, SecureApiServiceQueryVariables>(SecureApiServiceDocument, options);
      }
export function useSecureApiServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SecureApiServiceQuery, SecureApiServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SecureApiServiceQuery, SecureApiServiceQueryVariables>(SecureApiServiceDocument, options);
        }
export type SecureApiServiceQueryHookResult = ReturnType<typeof useSecureApiServiceQuery>;
export type SecureApiServiceLazyQueryHookResult = ReturnType<typeof useSecureApiServiceLazyQuery>;
export type SecureApiServiceQueryResult = Apollo.QueryResult<SecureApiServiceQuery, SecureApiServiceQueryVariables>;