import React, {
  useCallback,
  useEffect,
  useMemo,
} from "react";
import SwatchSelector from "../../SwatchSelector/SwatchSelector";
import { styled } from "@stitches/react";
import "../../../index.css";
import FilterIcon from "../../Icons/FilterIcon";
import ColorPaletteList from "../../ColorPaletteList/ColorPaletteList";
import { useState } from "react";
import MyColorPalette from "../../MyColorPalette/MyColorPalette";
import DownArrowIcon from "../../Icons/DownArrowIcon";
import {
  TypographyMedium,
  TypographyMedium2,
} from "../../DS/Typography";
import {
  Menu,
  MenuItem,
} from "@szhsin/react-menu";
import {
  useDeleteUserColorPaletteMutation,
  useGetPublicColorPaletteQuery,
  useUpdateUserColorPaletteNameMutation,
} from "../../../hooks/graphql/graphql";

const Select = styled("div", {
  boxSizing: "border-box",

  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "6px 8px 6px 8px",
  gap: "8px",
  width: "176px",
  height: "30px",
  background: "#FFFFFF",

  border: "1px solid #ECECEC",
  borderRadius: "3px",

  cursor: "pointer",
});

const ControlPanelColorPaletteTab = () => {
  const { data, loading } =
    useGetPublicColorPaletteQuery({
      fetchPolicy: "network-only",
    });

  const [doDelete] =
    useDeleteUserColorPaletteMutation({
      fetchPolicy: "network-only",
    });
  const [doEditName] =
    useUpdateUserColorPaletteNameMutation({
      fetchPolicy: "network-only",
    });
  const [filterId, setFilterId] =
    React.useState("");
  const [filterName, setFilterName] =
    React.useState("All Palettes");
  const [selectedValue, setSelectedValue] =
    useState("Featured Palettes");

  const myColors = useMemo(() => {
    return (
      data?.getPublicColorPalettes.data
        ?.filter((d) => d.user_type !== "Admin")
        .map((d) => ({
          id: d.id,
          name: d.color_palette_name,
          colors: d.colors,
        })) || []
    );
  }, [data]);
  const [myColorState, setMyColorState] =
    useState<
      {
        id: string;
        name: string;
        colors: string[];
      }[]
    >([]);

  useEffect(() => {
    setMyColorState(myColors);
  }, [myColors]);

  const onEdit = useCallback(
    (id: string, name: string) => {
      doEditName({
        variables: {
          id: id,
          name: name,
        },
      })
        .then(console.log)
        .catch(console.error);
      setMyColorState((d) =>
        d.map((d) => {
          if (d.id === id) {
            return {
              ...d,
              name,
            };
          }

          return d;
        })
      );
    },
    [doEditName]
  );

  const onDelete = useCallback(
    (id: string) => {
      doDelete({
        variables: {
          id: id,
        },
      })
        .then(console.log)
        .catch(console.error);
      setMyColorState((d) =>
        d.filter((d) => d.id !== id)
      );
    },
    [doDelete]
  );

  return (
    <>
      <div>
        <SwatchSelector />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "24px 24px",
            width: "360px",
            boxSizing: "border-box",
            position: "relative",
          }}
        >
          <Menu
            menuStyle={{
              width: 176,
            }}
            menuButton={
              <Select>
                <TypographyMedium2>
                  {selectedValue
                    ? selectedValue
                    : "Featured Palettes"}
                </TypographyMedium2>

                <DownArrowIcon />
              </Select>
            }
          >
            <MenuItem
              onClick={() => {
                setSelectedValue(
                  "Featured Palettes"
                );
              }}
            >
              <TypographyMedium2>
                Featured Palettes
              </TypographyMedium2>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSelectedValue("My Palettes");
              }}
            >
              <TypographyMedium2>
                My Palettes
              </TypographyMedium2>
            </MenuItem>
          </Menu>

          {selectedValue ===
          "Featured Palettes" ? (
            <div>
              <Menu
                menuButton={
                  <span
                    className="filter"
                    onClick={() => {
                      // setIsFilter(!isFilter);
                    }}
                  >
                    <TypographyMedium2>
                      Filter
                    </TypographyMedium2>

                    <FilterIcon />
                  </span>
                }
              >
                <MenuItem
                  onClick={() => {
                    setFilterId("");
                    setFilterName("All Palettes");
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent:
                        "space-between",
                      alignItems: "center",
                      gap: 8,
                    }}
                  >
                    <input
                      type="radio"
                      id="allPalettes"
                      name="filterItems"
                      value="allPalettes"
                      style={{
                        accentColor: "#000000",
                      }}
                      checked={filterId === ""}
                    />
                    All Palettes
                  </div>
                </MenuItem>
                {data?.getPublicColorPaletteGroups?.data?.map(
                  (data, index) => {
                    return (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          setFilterId(data.id);
                          setFilterName(
                            data.group_name
                          );
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent:
                              "space-between",
                            alignItems: "center",
                            gap: 8,
                          }}
                        >
                          <input
                            type="radio"
                            style={{
                              accentColor:
                                "#000000",
                            }}
                            name="filterItems"
                            value="allPalettes"
                            checked={
                              filterId === data.id
                            }
                          />
                          {data.group_name}
                        </div>
                      </MenuItem>
                    );
                  }
                )}
              </Menu>
            </div>
          ) : (
            <></>
          )}
        </div>
        {loading ? (
          <div
            style={{
              padding: "12px 24px",
            }}
          >
            <TypographyMedium2>
              Loading...
            </TypographyMedium2>
          </div>
        ) : (
          <div
            style={{
              maxHeight: 380,
              overflowY: "overlay" as any,
              overflowX: "hidden",
            }}
          >
            {selectedValue ===
            "Featured Palettes" ? (
              <ColorPaletteList
                colors={
                  data?.getPublicColorPalettes.data
                    ?.filter(
                      (d) =>
                        d.user_type === "Admin"
                    )
                    .filter((d) => {
                      if (filterId.length) {
                        return d.color_group_ids.includes(
                          filterId
                        );
                      }

                      return true;
                    })
                    .map((d) => ({
                      id: d.id,
                      name: d.color_palette_name,
                      colors: d.colors,
                    })) || []
                }
                title={filterName}
              />
            ) : (
              <ColorPaletteList
                editable={true}
                onEdit={onEdit}
                onDelete={onDelete}
                title="My Palettes"
                colors={myColorState}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ControlPanelColorPaletteTab;
