import React, { FC } from "react";
import Logo from "../DS/Logo";
import logo from "../../assets/logo2.svg";
import { styled } from "../../stitchesConfig";
import { keyframes } from "@stitches/react";
import { TypographyMedium } from "../DS/Typography";

const Container = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px",
  position: "fixed",
  width: "100vw",
  height: "100vh",
  top: 0,
  left: 0,
  zIndex: 10,
  background: "rgba(255, 255, 255, 0.8)",
  backdropFilter: "blur(10px)",
});

const scaleUp = keyframes({
  "0%": { transform: "translateX(0%)" },
  "100%": {
    transform: "translateX(calc(100% + 1px))",
  },
});
const LoadingBar = styled("div", {
  position: "relative",
  top: "0px",
  left: "0px",
  maxWidth: "378px",
  width: "100%",
  height: "16px",
  border: "1px solid #303030",
  borderRadius: "0.214em",
});
const LoadingSlider = styled("div", {
  position: "absolute",
  top: "0px",
  left: "0%",
  width: "50%",
  height: "16px",
  background: "#303030",
  animation: `${scaleUp} 1.5s alternate infinite ease-in-out`,
});

const SplashLoader: FC<{ text?: string }> = ({
  text = "Loading...",
}) => {
  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 24,
        }}
      >
        <Logo logo={logo} width="324.15px" height="108px" />

        <TypographyMedium color="#000000">
          {text}
        </TypographyMedium>

        <LoadingBar>
          <LoadingSlider />
        </LoadingBar>
        <TypographyMedium color="#888888">
          A custom coloring and re-sizing tool
        </TypographyMedium>
      </div>
    </Container>
  );
};

export default SplashLoader;
