import React from "react";

const DownArrowIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.77082 7.50001L10.0042 10.7333L13.2375 7.50001C13.5625 7.17501 14.0875 7.17501 14.4125 7.50001C14.7375 7.82501 14.7375 8.35001 14.4125 8.67501L10.5875 12.5C10.2625 12.825 9.73749 12.825 9.41249 12.5L5.58749 8.67501C5.43144 8.51931 5.34375 8.30794 5.34375 8.08751C5.34375 7.86707 5.43144 7.6557 5.58749 7.50001C5.91249 7.18334 6.44582 7.17501 6.77082 7.50001V7.50001Z"
        fill="#676767"
      />
    </svg>
  );
};

export default DownArrowIcon;
