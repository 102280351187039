import React, { FC, useMemo } from "react";
import ControlPanel from "./components/ControlPanel/ControlPanel";
import RecolorCanvas from "./components/RecolorCanvas/RecolorCanvas";
import Rulers from "./components/Rulers/Rulers";

import SplashLoader from "./components/SplashLoader/SplashLoader";
import { RecolorLayerProvider } from "./services/RecolorLayer/RecolorLayer";
import ActionBar from "./components/ActionBar/ActionBar";
import {
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  useGetAssetDetailByProductIdQuery,
  useGetRecoloredAssetsQuery,
} from "./hooks/graphql/graphql";

const App: FC<{ isNew: boolean }> = ({ isNew = false }) => {
  const { productId, recolorId } = useParams();

  const [params] = useSearchParams();
  const { basefabricname, inventcolorid, order_type } =
    useMemo(() => {
      return {
        inventcolorid: params.get("inventcolorid") || "99",
        basefabricname: params.get("basefabricname") || "",
        order_type: params.get("order_type") || "",
      };
    }, [params]);
  const { data, loading, error } =
    useGetAssetDetailByProductIdQuery({
      variables: {
        productId: productId!,
      },
      fetchPolicy: "network-only",
    });
  const recolorAssets = useGetRecoloredAssetsQuery({
    variables: {
      variantId: inventcolorid || "99",
      productId: productId!,
      isNewRecolorAsset: recolorId ? false : true,
      ...(recolorId
        ? {
            recolorId: recolorId,
          }
        : {}),
    },
    fetchPolicy: "network-only",
  });
  const finalLoading = useMemo(() => {
    return loading || recolorAssets.loading;
  }, [loading, recolorAssets.loading]);

  const finalRepeat = useMemo(() => {
    if (data && recolorAssets.data) {
      if (
        recolorAssets.data.getRecoloredAssets.data
          ?.repeats !== 0
      ) {
        return recolorAssets.data.getRecoloredAssets.data!
          .repeats;
      }
      return (
        data?.getAssetDetailByProductId.data
          ?.default_repeats || 1
      );
    }

    return 1;
  }, [data, recolorAssets]);

  const finalColors = useMemo(() => {
    if (
      data &&
      recolorAssets.data &&
      recolorAssets.data.getRecoloredAssets.data &&
      recolorAssets.data.getRecoloredAssets.data.new_colors
        .length
    ) {
      const hasVariant =
        (inventcolorid || "").length > 0 &&
        data.getAssetsVariants.filter(
          (d) => d.variantName === inventcolorid
        ).length > 0;
      let variantColors: string[] = [];
      if (hasVariant) {
        variantColors =
          data.getAssetsVariants.find(
            (d) => d.variantName === inventcolorid
          )?.colors || [];
      }
      const __colors =
        recolorAssets.data.getRecoloredAssets.data
          ?.new_colors || [];
      const _finalColors = (
        (variantColors.length
          ? variantColors
          : data.getAssetDetailByProductId.data?.colors) ||
        []
      ).map((_c, index) => __colors[index] || _c);

      return _finalColors;
    }
    if (data) {
      const hasVariant =
        (inventcolorid || "").length > 0 &&
        data.getAssetsVariants.filter(
          (d) => d.variantName === inventcolorid
        ).length > 0;
      let variantColors: string[] = [];
      if (hasVariant) {
        variantColors =
          data.getAssetsVariants.find(
            (d) => d.variantName === inventcolorid
          )?.colors || [];
      }

      return (
        (variantColors.length
          ? variantColors
          : data.getAssetDetailByProductId.data?.colors) ||
        []
      );
    }
    return [];
  }, [data, inventcolorid, recolorAssets]);

  if (!productId) {
    return <div>Invalid route</div>;
  }

  if (error && !finalLoading) {
    return <div>Error</div>;
  }

  if (finalLoading && !data) {
    return <SplashLoader text="Loading Recolor Asset..." />;
  }

  if (
    data &&
    data.getAssetDetailByProductId.status !== "Success"
  ) {
    return <div>Asset Not Found</div>;
  }
  console.log("IsNew", isNew);
  return (
    <RecolorLayerProvider
      isNew={isNew}
      colors={finalColors}
      designName={
        recolorAssets.data?.getRecoloredAssets.data
          ?.design_name || ""
      }
      lockedLayers={
        data?.getAssetDetailByProductId.data
          ?.lockedLayers || []
      }
      layers={(
        data?.getAssetDetailByProductId.data
          ?.low_resolution_layers || []
      ).map((d, i) => `${d}?${Date.now()}${i}`)}
      maxRepeats={
        data?.getAssetDetailByProductId.data?.maxRepeats ||
        108
      }
      minRepeats={
        data?.getAssetDetailByProductId.data?.minRepeats ||
        1
      }
      defaultRepeats={
        data?.getAssetDetailByProductId.data
          ?.default_repeats || 1
      }
      repeats={finalRepeat}
      productId={productId}
      assetId={
        recolorAssets.data?.getRecoloredAssets.data?.id ||
        ""
      }
      sessionId={
        recolorAssets.data?.getRecoloredAssets.data
          ?.session_id || ""
      }
    >
      <div
        id="recolor-container"
        style={{
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <RecolorCanvas />
        </div>

        <ActionBar />
        <Rulers />
        <ControlPanel />
      </div>
    </RecolorLayerProvider>
  );
};

export default App;
