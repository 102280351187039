import React from "react";

const DragIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16634 15C9.16634 15.9167 8.41634 16.6667 7.49967 16.6667C6.58301 16.6667 5.83301 15.9167 5.83301 15C5.83301 14.0833 6.58301 13.3333 7.49967 13.3333C8.41634 13.3333 9.16634 14.0833 9.16634 15ZM7.49967 8.33334C6.58301 8.33334 5.83301 9.08334 5.83301 10C5.83301 10.9167 6.58301 11.6667 7.49967 11.6667C8.41634 11.6667 9.16634 10.9167 9.16634 10C9.16634 9.08334 8.41634 8.33334 7.49967 8.33334ZM7.49967 3.33334C6.58301 3.33334 5.83301 4.08334 5.83301 5.00001C5.83301 5.91668 6.58301 6.66668 7.49967 6.66668C8.41634 6.66668 9.16634 5.91668 9.16634 5.00001C9.16634 4.08334 8.41634 3.33334 7.49967 3.33334ZM12.4997 6.66668C13.4163 6.66668 14.1663 5.91668 14.1663 5.00001C14.1663 4.08334 13.4163 3.33334 12.4997 3.33334C11.583 3.33334 10.833 4.08334 10.833 5.00001C10.833 5.91668 11.583 6.66668 12.4997 6.66668ZM12.4997 8.33334C11.583 8.33334 10.833 9.08334 10.833 10C10.833 10.9167 11.583 11.6667 12.4997 11.6667C13.4163 11.6667 14.1663 10.9167 14.1663 10C14.1663 9.08334 13.4163 8.33334 12.4997 8.33334ZM12.4997 13.3333C11.583 13.3333 10.833 14.0833 10.833 15C10.833 15.9167 11.583 16.6667 12.4997 16.6667C13.4163 16.6667 14.1663 15.9167 14.1663 15C14.1663 14.0833 13.4163 13.3333 12.4997 13.3333Z"
        fill="#676767"
      />
    </svg>
  );
};

export default DragIcon;
