import { createStitches } from "@stitches/react";

export const { styled, css } = createStitches({
  media: {
    xsm: "(min-width: 1280px)",
    sm: "(min-width: 1366px)",
    md: "(min-width: 1440px)",
    lg: "(min-width : 1680px)",
    xlg: "(min-width : 1920px)",
  },
});
