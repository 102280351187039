import { styled } from "@stitches/react";
import React, { FC } from "react";
import Logo from "../DS/Logo";
import DownArrowIcon from "../Icons/DownArrowIcon";
import DragIcon from "../Icons/DragIcon";
import logo from "../../assets/logo.svg";
import Tooltip from "../Tooltip/Tooltip";

const StyledHeader = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "4px 8px",

  position: "static",
  width: "360px",
  height: "32px",
  left: " 0px",
  top: "0px",

  background: "#ECECEC",
  borderRadius: " 3px 3px 0px 0px",

  flex: " none",
  order: 0,
  alignSelf: "stretch",
  flexGrow: 0,
  margin: "0px 0px",
  boxSizing: "border-box",
});

const Drag = styled("span", {
  position: "static",
  width: "20px",
  height: "20px",
  left: "332px",
  top: "6px",
  flex: "none",
  order: 2,
  flexGrow: 0,
  margin: "0px 10px",
  cursor: "grab",
  "&:active": {
    cursor: "grabbing",
  },
});

const DownArrow = styled("span", {
  position: "static",
  width: "20px",
  height: "20px",
  left: "0.571em",
  top: "0.43em",
  flex: "none",
  order: 0,
  flexGrow: 0,
  margin: "0em 0.714em",
  cursor: "pointer",
});

const ControlPanelHeader: FC<{
  handleCollapsed?: () => void;
  collapsed: boolean;
}> = ({ handleCollapsed = () => {}, collapsed }) => {
  return (
    <StyledHeader>
      <Tooltip
        text={collapsed ? "Maximize" : "Minimize"}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DownArrow onClick={handleCollapsed}>
          <DownArrowIcon />
        </DownArrow>
      </Tooltip>
      <Logo logo={logo} />
      <Tooltip
        isRight={true}
        text={"Drag window"}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Drag id="drag-handle">
          <DragIcon />
        </Drag>
      </Tooltip>
    </StyledHeader>
  );
};

export default ControlPanelHeader;
