import React from "react";

const DeleteIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9167 3.33333H15.0001C15.4584 3.33333 15.8334 3.70833 15.8334 4.16667C15.8334 4.625 15.4584 5 15.0001 5H5.00008C4.54175 5 4.16675 4.625 4.16675 4.16667C4.16675 3.70833 4.54175 3.33333 5.00008 3.33333H7.08342L7.67508 2.74167C7.82508 2.59167 8.04175 2.5 8.25842 2.5H11.7417C11.9584 2.5 12.1751 2.59167 12.3251 2.74167L12.9167 3.33333ZM6.66675 17.5C5.75008 17.5 5.00008 16.75 5.00008 15.8333V7.5C5.00008 6.58333 5.75008 5.83333 6.66675 5.83333H13.3334C14.2501 5.83333 15.0001 6.58333 15.0001 7.5V15.8333C15.0001 16.75 14.2501 17.5 13.3334 17.5H6.66675Z"
        fill="#676767"
      />
    </svg>
  );
};

export default DeleteIcon;
