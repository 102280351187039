import React, { useMemo } from "react";
import "../../index.css";
import { useState } from "react";
import {
  styled,
  css,
} from "../../stitchesConfig";
import { useRecolorRepeats } from "../../services/RecolorLayer/RecolorLayer";

const Label = styled("div", {
  position: "static",

  cursor: "default",
});

const StyledValue = styled("p", {
  position: "relative",
  fontFamily: "Spartan",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "0.857em",
  lineHeight: "1.5em",

  display: "flex",
  textAlign: "center",

  cursor: "default",
});
export const valuesMap = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 15, 16, 27,
  32, 36, 54, 64, 108,
];
const ScaleRangeBar = () => {
  const {
    currentRepeats,
    maxRepeats,
    minRepeats,
    onRepeatsChange,
  } = useRecolorRepeats();

  const value = useMemo(
    () => {
      const repeatMapIndex = valuesMap.findIndex(
        (value) => value === currentRepeats
      );
      return (repeatMapIndex === -1) ? 0 : repeatMapIndex;
    },
    [currentRepeats]
  );
  const min = useMemo(
    () =>
      valuesMap.findIndex(
        (value) => value === minRepeats
      ) || 0,
    [minRepeats]
  );
  const max = useMemo(
    () =>
      valuesMap.findIndex(
        (value) => value === maxRepeats
      ) || 18,
    [maxRepeats]
  );

  const getBackgroundSize = useMemo(() => {
    return {
      backgroundSize: `${
        ((value - min) * 100) / (max - min)
      }% 100%`,
    };
  }, [value, min, max]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "40px",
        marginLeft: "24px",
        marginRight: "24px",
      }}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <Label>Number of repeats</Label>
      </div>
      <input
        type="range"
        min={min}
        max={max}
        name="numberOfRepeats"
        value={value}
        className={`scaleRangeBar ${
          value > 50 ? "slider-50" : "slider-0"
        }`}
        style={getBackgroundSize}
        onChange={(e: any) => {
          onRepeatsChange(
            valuesMap[
              parseInt(e.target.value) || 0
            ]
          );
        }}
      />
      <StyledValue>
        {`${valuesMap[value]} Repeats`}
      </StyledValue>
    </div>
  );
};

export default ScaleRangeBar;
