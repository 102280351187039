import { styled } from "@stitches/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ChromePicker, ColorResult } from "react-color";
import { EditableInput } from "react-color/lib/components/common";
import "../../../index.css";

import AddIcon from "../../Icons/AddIcon";
import DeleteIcon from "../../Icons/DeleteIcon";
import DoneIcon from "../../Icons/DoneIcon";
import CancelIcon from "../../Icons/CancelIcon";
import SwatchSelector from "../../SwatchSelector/SwatchSelector";
import DownArrowIcon from "../../Icons/DownArrowIcon";
import { useLayerCurrentColor } from "../../../services/RecolorLayer/RecolorLayer";
import { colord, extend } from "colord";
import labPlugin from "colord/plugins/lab";
import {
  Menu,
  MenuItem,
  MenuButton,
} from "@szhsin/react-menu";
import TypographySmall, {
  TypographyMedium,
  TypographyMedium2,
} from "../../DS/Typography";
import useSaveMyColors from "../../../hooks/useSaveMyColors";
import Tooltip from "../../Tooltip/Tooltip";

extend([labPlugin]);

const PresetColors = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  padding: "0.714em",
  position: "static",
  width: "312px",
  left: "0em",
  top: "0em",
  flex: "none",
  order: 0,
  flexGrow: 0,
  marginLeft: "0.357em",
});

const Button = styled("span", {
  position: "relative",
  width: "312px",
  height: "24px",
  right: "-24px",

  textDecorationLine: " underline",

  cursor: "pointer",

  flex: "none",
  order: 0,
  flexGrow: 1,
});

const PaletteName = styled("input", {
  boxSizing: "border-box",
  padding: "0.5em 0.571em 0.357em",
  width: "248px",
  height: "32px",
  background: "#FAFAFA",
  border: "1px solid #D2D2D2",
  borderRadius: "3px",
  margin: "0em 1.714em",
  marginRight: "11.61px",
  fontFamily: "Spartan",
  fontWeight: 500,
});

const Select = styled("div", {
  boxSizing: "border-box",

  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "0em",
  gap: "4px",

  position: "relative",
  left: "1.357em",
  width: "72px",
  height: "28px",

  border: "none",

  fontFamily: "Spartan",
  fontFtyle: "normal",
  fontWeight: 500,
  fontSize: "0.857em",
  lineHeight: "1.14em",

  textAlign: "center",

  color: " #5E6875",

  flex: "none",
  order: 0,
  flexGrow: 0,

  margin: "0em 0.286em ",
  cursor: "pointer",
});

const ControlPanelEditColorTab: React.FC = () => {
  const [currentColor, onColorChange] =
    useLayerCurrentColor();
  const [color, setColor] = useState(currentColor);
  const [tempColor, setTempColor] = useState(currentColor);

  const [colorList, setColorList] = useState<string[]>([]);
  const RGB = useMemo(
    () => colord(tempColor).toRgb(),
    [tempColor]
  );
  const LAB = useMemo(
    () => colord(tempColor).toLab(),
    [tempColor]
  );

  const [palletName, setPalletName] = useState("");
  const [isPalleteSaved, setIsPalleteSaved] =
    useState(false);

  const [selectedItem, setSelectedItem] =
    useState<number>(-1);
  const [selectedValue, setSelectedValue] = useState("HEX");

  const { isSaved, isSaving, onSave } = useSaveMyColors();
  useEffect(() => {
    if (isSaved) {
      setColorList([]);
      setPalletName("");
      setIsPalleteSaved(false);
    }
  }, [isSaved, isSaving]);

  const handleColorChange = useCallback(
    (color: ColorResult) => {
      // setColor(color.hex);
      if (selectedItem !== -1) {
        setColorList((colorList) => {
          colorList[selectedItem] = color.hex;
          return colorList;
        });
      }
      onColorChange(color.hex);
    },
    [onColorChange, selectedItem]
  );
  const handleColorValueChange = useCallback(
    (color: string) => {
      // setColor(color.hex);
      if (selectedItem !== -1) {
        setColorList((colorList) => {
          colorList[selectedItem] = color;
          return colorList;
        });
      }
      onColorChange(color);
    },
    [onColorChange, selectedItem]
  );

  const handleOptions = useCallback((e: any) => {
    setSelectedValue(e.target.value);
  }, []);

  useEffect(() => {
    if (color !== currentColor) {
      setColor(currentColor);
      setTempColor(currentColor);
    }
  }, [color, currentColor]);

  return (
    <>
      <SwatchSelector />
      <div className="colorPicker">
        <ChromePicker
          color={tempColor}
          onChange={(color) => {
            setTempColor(color.hex);
          }}
          onChangeComplete={handleColorChange}
          disableAlpha={true}
          className="colorPicker"
          styles={{
            default: {
              picker: {
                boxShadow: "none",
                backgroundColor: "transparent",
                margin: "0px",
                padding: "0px",
              },
              saturation: {
                width: "312px",
                // height: "5rem",
                height: "80px",
                // margin: "10% 10% 0% 10%",
                margin: "22px 22px 0px 22px",
                cursor: "pointer",
              },
              hue: {
                width: "310px",
                height: "16px",
                cursor: "pointer",
              },
            },
          }}
        />

        <div>
          <Menu
            menuButton={
              <Select onChange={(e) => handleOptions(e)}>
                {selectedValue ? selectedValue : "HEX"}
                <span
                  style={{
                    position: "relative",
                    top: "2px",
                  }}
                >
                  <DownArrowIcon />
                </span>
              </Select>
            }
            menuStyle={{
              maxWidth: 80,
              minWidth: 80,
            }}
          >
            <MenuItem
              onClick={() => {
                setSelectedValue("HEX");
              }}
            >
              HEX
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSelectedValue("RGB");
              }}
            >
              RGB
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSelectedValue("LAB");
              }}
            >
              LAB
            </MenuItem>
          </Menu>
        </div>

        {selectedValue === "HEX" ? (
          <div className="hexInput">
            <EditableInput
              value={(tempColor || "").toUpperCase()}
              onChange={(color: any) => {
                const _color = colord(color);
                if (_color.isValid()) {
                  handleColorValueChange(
                    _color
                      .alpha(1)
                      .toHex()
                      .toLocaleUpperCase()
                  );
                }
              }}
            />
          </div>
        ) : selectedValue === "RGB" ? (
          <div className="rgbInput">
            <EditableInput
              value={RGB.r}
              onChange={(r: any) => {
                if (!r) {
                  return;
                }
                const _color = colord({
                  r,
                  g: RGB.g,
                  b: RGB.b,
                }).toHex();
                handleColorValueChange(_color);
                //setColor(rgbToHex(r, g, b));
              }}
            />
            <EditableInput
              value={RGB.g}
              onChange={(g: any) => {
                if (!g) {
                  return;
                }
                const _color = colord({
                  r: RGB.r,
                  g,
                  b: RGB.b,
                }).toHex();
                handleColorValueChange(_color);
              }}
            />
            <EditableInput
              value={RGB.b}
              onChange={(b: any) => {
                if (!b) {
                  return;
                }
                const _color = colord({
                  r: RGB.r,
                  g: RGB.g,
                  b,
                }).toHex();
                handleColorValueChange(_color);
              }}
            />
          </div>
        ) : (
          <div className="rgbInput">
            <EditableInput
              value={LAB.l}
              onChange={(l: any) => {
                if (!l) {
                  return;
                }
                const _color = colord({
                  l,
                  a: LAB.a,
                  b: LAB.b,
                })
                  .alpha(1)
                  .toHex();
                handleColorValueChange(_color);
              }}
            />
            <EditableInput
              value={LAB.a}
              onChange={(a: any) => {
                if (!a) {
                  return;
                }
                const _color = colord({
                  l: LAB.l,
                  a,
                  b: LAB.b,
                })
                  .alpha(1)
                  .toHex();
                handleColorValueChange(_color);
              }}
            />
            <EditableInput
              value={LAB.b}
              onChange={(b: any) => {
                if (!b) {
                  return;
                }
                const _color = colord({
                  l: LAB.l,
                  a: LAB.a,
                  b,
                })
                  .alpha(1)
                  .toHex();
                handleColorValueChange(_color);
              }}
            />
          </div>
        )}
        <hr
          style={{
            marginTop: "12px",
            marginLeft: "24px",
            marginBottom: "0px",
            borderColor: "#ececec",
          }}
        />
        <div
          style={{
            marginTop: 12,
            marginLeft: 24,
          }}
        >
          <TypographySmall>
            Create My Palette
          </TypographySmall>
        </div>
        <PresetColors>
          {colorList.map((presetColor, index) => (
            <div
              key={index}
              style={{
                background: presetColor,
                boxShadow:
                  selectedItem === index
                    ? "0px 0px 0px 1px rgb(48, 48, 48)"
                    : "0px 0px 0px 0px rgb(48, 48, 48)",
              }}
              className="colorPalete"
              id="colorPalete"
              onClick={(e) => {
                if (selectedItem === index)
                  setSelectedItem(-1);
                else setSelectedItem(index);
              }}
            />
          ))}
          <div
            className="colorPalete"
            style={{
              cursor: "pointer",
              display:
                colorList.length >= 12 ? "none" : "block",
            }}
            onClick={() => {
              if (colorList.length >= 12) {
                return;
              }
              setSelectedItem(-1);
              setColorList((colors) => [...colors, color]);
            }}
          >
            <Tooltip text="Add">
              <AddIcon />
            </Tooltip>
          </div>
        </PresetColors>
        {colorList.length ? (
          <>
            <hr
              style={{
                marginTop: "-4px",
                marginLeft: "24px",
                borderColor: "#ececec",
              }}
            />
            {selectedItem !== -1 && !isPalleteSaved ? (
              <span
                id="deletePresetColor"
                onClick={() => {
                  setColorList((colorList) =>
                    colorList.filter((_, i) => {
                      return i !== selectedItem;
                    })
                  );
                  setSelectedItem(-1);
                }}
              >
                <Tooltip text="Delete" isRight>
                  <DeleteIcon />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
            {isPalleteSaved ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PaletteName
                    type="text"
                    name="name"
                    placeholder="Palette Name"
                    onChange={(e) => {
                      setPalletName(e.target.value);
                    }}
                    disabled={isSaving}
                    value={palletName}
                  />
                  <span
                    onClick={() => {
                      if (!isSaving)
                        onSave(colorList, palletName);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Tooltip text="Save">
                      <DoneIcon />
                    </Tooltip>
                  </span>
                  <span
                    onClick={() => {
                      if (!isSaving)
                        setIsPalleteSaved(false);
                    }}
                    style={{
                      marginLeft: "17.01px",
                      cursor: "pointer",
                    }}
                  >
                    <Tooltip text="Cancel" isRight>
                      <CancelIcon />
                    </Tooltip>
                  </span>
                </div>
              </div>
            ) : (
              <Button
                onClick={() => setIsPalleteSaved(true)}
              >
                Save as palette
              </Button>
            )}
            {console.log(isSaved)}
          </>
        ) : null}
        {isSaved ? (
          <div
            style={{
              color: "#2BA928",
              marginLeft: "24px",
              marginTop: 12,
              fontFamily: "Spartan",
            }}
          >
            <TypographySmall color="#2BA928">
              Palette saved to My Palettes
            </TypographySmall>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ControlPanelEditColorTab;
