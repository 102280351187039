import React from "react";

const DoneIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.79992 15.905L5.29992 12.405C5.11507 12.2179 4.86297 12.1125 4.59992 12.1125C4.33687 12.1125 4.08478 12.2179 3.89992 12.405C3.50992 12.795 3.50992 13.415 3.89992 13.805L8.08992 17.995C8.47992 18.385 9.10992 18.385 9.49992 17.995L20.0999 7.40501C20.4899 7.01501 20.4899 6.39501 20.0999 6.00501C19.9151 5.81786 19.663 5.71252 19.3999 5.71252C19.1369 5.71252 18.8848 5.81786 18.6999 6.00501L8.79992 15.905Z"
        fill="#2BA928"
      />
    </svg>
  );
};

export default DoneIcon;
