import Konva from "konva";
import { Image as KImage } from "konva/lib/shapes/Image";
import React, {
  FC,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { Image } from "react-konva";
import useImage from "use-image";
import { colord, RgbaColor } from "colord";
import useRecolorImage from "../../hooks/useRecolorImage";
import {
  RecolorLayerType,
  useRecolor,
} from "../../services/RecolorLayer/RecolorLayer";
import useScreenSize from "@tinyd/usescreensize";

function scale(
  number: number,
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number
) {
  return (
    ((number - inMin) * (outMax - outMin)) /
      (inMax - inMin) +
    outMin
  );
}

const CustomFilter =
  (id: number, color: RgbaColor) =>
  (imgData: ImageData) => {
    // console.log(imgData, id, color);
    var nPixels = imgData.data.length;
    for (var i = 3; i < nPixels; i += 4) {
      // if (imgData.data[i] === 255) {
      imgData.data[i - 3] = color.r;
      imgData.data[i - 2] = color.g;
      imgData.data[i - 1] = color.b;
      // }
      //imgData.data[i] = 255;
    }
    // console.log(imgData, id, color, "edit");
  };

const LayerImg: FC<{
  src: string;
  hexColor?: string;
  id: number;
  recolor: RecolorLayerType;
}> = ({
  src,
  hexColor = "#000000",
  id,
  recolor,
}) => {
  const { height, width } = useScreenSize();
  const finalColor = useMemo(
    () => colord(hexColor).toRgb(),
    [hexColor]
  );
  const { recolorImage, status } =
    useRecolorImage(src, finalColor);
  const ref = useRef<KImage>(null);

  useEffect(() => {
    if (
      ref &&
      ref.current &&
      status === "done" &&
      recolor.layersStatus[id] !== "done"
    ) {
      // console.log(ref, status);
      recolor.onStatusChange(id, "done");
    }
  }, [id, recolor, ref, status]);
  const finalSize = useMemo(() => {
    return (
      (width - 24) /
      (recolorImage?.width || 0) /
      recolor.currentRepeats
    );
  }, [
    recolor.currentRepeats,
    recolorImage?.width,
    width,
  ]);

  useEffect(() => {
    if (status === "done" && ref && ref.current) {
      ref.current.filters([
        CustomFilter(id, finalColor),
      ]);
      ref.current.fillPatternScale({
        x: finalSize,
        y: finalSize,
      });

      ref.current.cache();
    }
  }, [finalColor, id, ref, status, finalSize]);

  return (
    <Image
      fillPatternX={24}
      fillPatternY={24}
      image={undefined}
      fillPatternImage={recolorImage}
      fillPatternScale={{
        x: 1,
        y: 1,
      }}
      width={width}
      height={height}
      ref={ref}
    />
  );
};

export default LayerImg;
