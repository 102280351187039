import React, { FC, useState } from "react";
import { styled } from "@stitches/react";
import { TypographyMedium2 } from "../DS/Typography";
import { useLayerCurrentColor } from "../../services/RecolorLayer/RecolorLayer";
import EditIcon from "../Icons/EditIcon";
import DeleteIcon from "../Icons/DeleteIcon";
import DoneIcon from "../Icons/DoneIcon";
import CancelIcon from "../Icons/CancelIcon";

const ListTitle = styled("div", {
  marginLeft: 24,
  marginTop: "20px",
});

const PaletteName = styled("div", {
  paddingTop: 8,
  paddingLeft: 24,

  paddingBottom: "1.143em",
});
const ColorPaleteContainer = styled("div", {
  display: "flex",
  flexDirection: "row",

  padding: "0px",

  height: "32px",
  overflow: "hidden",
  border: " 1px solid #303030",
  borderRadius: "3px",
  marginLeft: 24,
  marginRight: 24,
  boxSizing: "border-box",
  position: "relative",
});

const PaletteNameInput = styled("input", {
  boxSizing: "border-box",
  padding: "0.5em 0.571em 0.357em",
  width: "248px",
  height: "32px",
  background: "#FAFAFA",
  border: "1px solid #D2D2D2",
  borderRadius: "3px",
  fontFamily: "Spartan",
  fontWeight: 500,
});

const ColorPalette = styled("span", {
  flex: 1,
  height: "32px",
  cursor: "pointer",
});
const ColorPaletteList: FC<{
  editable?: boolean;
  onEdit?: (id: string, name: string) => any;
  onDelete?: (id: string) => any;
  colors: {
    id: string;
    name: string;
    colors: string[];
  }[];
  title: string;
}> = ({
  colors,
  title,
  editable = false,
  onDelete = () => {},
  onEdit = () => {},
}) => {
  const [editValue, setEditValue] = useState("");
  const [showAction, setShowAction] = useState<{
    action: "delete" | "edit" | "none";
    id: string;
  }>({
    action: "none",
    id: "",
  });
  const [_, onColorChange] =
    useLayerCurrentColor();
  return (
    <div>
      <ListTitle>
        <TypographyMedium2>
          {title}
        </TypographyMedium2>
      </ListTitle>
      <br />
      <div>
        {colors.map((data, i) => (
          <div className="palettes" key={i}>
            <ColorPaleteContainer>
              {data.colors.map((color, ci) => {
                return (
                  <ColorPalette
                    key={ci}
                    style={{ background: color }}
                    onClick={() => {
                      onColorChange(color);
                    }}
                  ></ColorPalette>
                );
              })}
            </ColorPaleteContainer>
            {showAction.id === data.id &&
            (showAction.action === "delete" ||
              showAction.action === "edit") ? (
              <div
                style={{
                  paddingLeft: 24,
                  paddingRight: 24,
                }}
              >
                {showAction.action ===
                "delete" ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent:
                        "space-between",
                      alignItems: "center",
                      marginTop: 8,
                      marginBottom: 8,
                    }}
                  >
                    <TypographyMedium2>
                      Are you sure?
                    </TypographyMedium2>
                    <div
                      style={{
                        display: "flex",
                        gap: 8,
                      }}
                    >
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          onDelete(data.id);
                          setShowAction({
                            action: "none",
                            id: "",
                          });
                        }}
                      >
                        <DoneIcon />
                      </div>
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowAction({
                            action: "none",
                            id: "",
                          });
                        }}
                      >
                        <CancelIcon />
                      </div>
                    </div>
                  </div>
                ) : null}
                {showAction.action === "edit" ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent:
                        "space-between",
                      alignItems: "center",
                      marginTop: 8,
                      marginBottom: 8,
                    }}
                  >
                    <PaletteNameInput
                      value={editValue}
                      onChange={(e) => {
                        setEditValue(
                          e.target.value
                        );
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        gap: 8,
                      }}
                    >
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          onEdit(
                            data.id,
                            editValue
                          );
                          setShowAction({
                            action: "none",
                            id: "",
                          });
                        }}
                      >
                        <DoneIcon />
                      </div>
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowAction({
                            action: "none",
                            id: "",
                          });
                        }}
                      >
                        <CancelIcon />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <PaletteName>
                  <TypographyMedium2>
                    {data.name}
                  </TypographyMedium2>
                </PaletteName>
                {editable ? (
                  <div
                    style={{
                      paddingRight: 24,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      gap: 8,
                      paddingBottom: 8,
                    }}
                  >
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setEditValue(data.name);
                        setShowAction({
                          action: "edit",
                          id: data.id,
                        });
                      }}
                    >
                      <EditIcon />
                    </div>
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setShowAction({
                          action: "delete",
                          id: data.id,
                        })
                      }
                    >
                      <DeleteIcon />
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColorPaletteList;
