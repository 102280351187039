import { styled } from "@stitches/react";
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import SwatchItem from "./SwatchItem";
import Tooltip from "../Tooltip/Tooltip";
import "../../index.css";
import DownArrowIcon from "../Icons/DownArrowIcon";
import UpArrowIcon from "../Icons/UpArrowIcon";
import ColorPickIcon from "../Icons/ColorPickIcon";
import ColorBucketIcon from "../Icons/ColorBucketIcon";
import { useRecolor } from "../../services/RecolorLayer/RecolorLayer";

const SwatchContainer = styled("div", {
  // alignItems: "flex-end",
  position: "absolute",
  width: 40,
  height: "495px",
  left: -28,
  top: 50,

  "& > .item-holder": {
    width: 150,
    left: -20,
    paddingLeft: 20,
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "column",
    justifyContent: "flex-start",
    position: "relative",
    overflow: "hidden",
    height: "495px",
    // backgroundColor: "red",
    gap: 8,
  },
});
const SwatchNavigator = styled("div", {
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "40px",
  height: "40px",
  minWidth: "40px",
  minHeight: "40px",

  background: "#FFFFFF",
  boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "40px",

  transition: "0.2s",
  zIndex: 6,
  cursor: "pointer",
  "&:hover, &:focus": {
    background: "#ECECEC",
  },
  "&:active": {
    background: "#D2D2D2",
  },
});

const SwatchSelector = () => {
  const recolor = useRecolor();
  const containerRef = useRef<HTMLDivElement>(null);
  const rootContainerRef = useRef<HTMLDivElement>(null);
  const swatches = useMemo(
    () => recolor.currentColors,
    [recolor]
  );
  const lockedLayersCount = useMemo(
    () =>
      recolor.lockedLayers.filter((d) => d === false)
        .length,
    [recolor]
  );

  const next = useCallback(() => {
    if (containerRef.current) {
      if (
        containerRef.current.scrollTop <
        containerRef.current.scrollHeight -
          containerRef.current.clientHeight
      )
        containerRef.current.scrollBy({
          top: 100,
          behavior: "smooth",
        });
    }
  }, [containerRef]);
  const prev = useCallback(() => {
    if (containerRef.current) {
      if (containerRef.current.scrollTop > 0)
        containerRef.current.scrollBy({
          top: -100,
          behavior: "smooth",
        });
    }
  }, [containerRef]);

  return (
    <SwatchContainer ref={rootContainerRef}>
      <div className="item-holder" ref={containerRef}>
        <SwatchNavigator
          style={{
            position: "sticky",
            top: 0,

            opacity: lockedLayersCount > 9 ? 1 : 0,
            pointerEvents:
              lockedLayersCount > 9 ? "auto" : "none",
          }}
        >
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: " flex-start",
              justifyContent: "center",
              position: "relative",
              left: "0em",
              top: "0em",
            }}
            onClick={prev}
          >
            <UpArrowIcon />
          </span>
        </SwatchNavigator>

        {swatches.map((color, index) =>
          recolor.lockedLayers[index] ? null : (
            <div className="item" key={index}>
              <SwatchItem
                color={color}
                id={index}
                mainRef={containerRef}
              />
            </div>
          )
        )}

        <SwatchNavigator
          style={{
            position: "sticky",
            bottom: 0,
            opacity: lockedLayersCount > 9 ? 1 : 0,
            pointerEvents:
              lockedLayersCount > 9 ? "auto" : "none",
          }}
        >
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: " flex-start",
              justifyContent: "center",
              position: "relative",
              left: "0em",
              top: "0.143em",
            }}
            onClick={next}
          >
            <DownArrowIcon />
          </span>
        </SwatchNavigator>
      </div>
    </SwatchContainer>
  );
};

export default SwatchSelector;
