import React, { FC, useState } from "react";
import ControlPanelEditColorTab from "./ControlPanelTabs/ControlPanelEditColorTab";
import ControlPanelHeader from "./ControlPanelHeader";
import ControlPanelScaleTab from "./ControlPanelTabs/ControlPanelScaleTab";
import ControlPanelColorPaletteTab from "./ControlPanelTabs/ControlPanelColorPaletteTab";
import Draggable, { DraggableProps } from "react-draggable";
import "../../index.css";
import { styled } from "@stitches/react";
import { TypographyMedium2 } from "../DS/Typography";
import { useRecolorPreview } from "../../services/RecolorLayer/RecolorLayer";

const DraggableC: FC<Partial<DraggableProps>> =
  Draggable as any;

const UnderLine = styled("div", {
  width: "97px",
  height: "2px",

  background: "#303030",

  flex: "none",
  order: 1,
  alignSelf: "stretch",
  flexGrow: 0,
});

const ControlPanel = () => {
  const [active, setActive] = useState("Edit Color");
  const [collapsed, setCollapsed] = useState(false);
  const { isPreviewMode } = useRecolorPreview();

  return (
    <span
      className={
        isPreviewMode
          ? "controlPanelContainerHolder previewMode"
          : "controlPanelContainerHolder"
      }
    >
      <DraggableC
        handle="#drag-handle"
        allowAnyClick={true}
        axis="both"
        bounds="#recolor-container"
      >
        <div
          className={
            !collapsed
              ? `controlPanelContainer`
              : `controlPanelContainer controlPanelContainerHidden`
          }
          id="controlPanelContainer"
        >
          <div>
            <ControlPanelHeader
              collapsed={collapsed}
              handleCollapsed={() =>
                setCollapsed(!collapsed)
              }
            />
          </div>

          {!collapsed ? (
            <div className="tabs">
              <span
                className="tab"
                style={{ marginLeft: "1.571em" }}
                onClick={() => setActive("Edit Color")}
              >
                <TypographyMedium2 color="#777777">
                  Edit Color
                  {active === "Edit Color" ? (
                    <UnderLine style={{ width: "75px" }} />
                  ) : (
                    <></>
                  )}
                </TypographyMedium2>
              </span>

              <span
                className="tab"
                style={{
                  width: "96px",
                  marginRight: "0em",
                  marginLeft: "0em",
                }}
                onClick={() => setActive("Scale Design")}
              >
                <TypographyMedium2 color="#777777">
                  Scale Design
                  {active === "Scale Design" ? (
                    <UnderLine />
                  ) : (
                    <></>
                  )}
                </TypographyMedium2>
              </span>
              <span
                className="tab"
                style={{
                  width: "98px",
                  marginRight: "1.571em",
                }}
                onClick={() => setActive("Color Palette")}
              >
                <TypographyMedium2 color="#777777">
                  Color Palette
                  {active === "Color Palette" ? (
                    <UnderLine />
                  ) : (
                    <></>
                  )}
                </TypographyMedium2>
              </span>
            </div>
          ) : (
            <></>
          )}
          {!collapsed ? (
            active === "Edit Color" ? (
              <ControlPanelEditColorTab />
            ) : active === "Scale Design" ? (
              <ControlPanelScaleTab />
            ) : (
              <ControlPanelColorPaletteTab />
            )
          ) : null}
        </div>
      </DraggableC>
    </span>
  );
};

export default ControlPanel;
