import React, {
  FC,
  Ref,
  RefObject,
  useRef,
  useState,
} from "react";
import RightArrowIcon from "../Icons/RightArrowIcon";
import "../../index.css";
import Tooltip from "../Tooltip/Tooltip";
import ColorPickIcon from "../Icons/ColorPickIcon";
import ColorBucketIcon from "../Icons/ColorBucketIcon";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import { useCurrentLayerSelector } from "../../services/RecolorLayer/RecolorLayer";

const SwatchItem: FC<{
  color: string;
  mainRef?: RefObject<HTMLDivElement>;
  id: number;
}> = ({ color, mainRef, id }) => {
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {
    root: mainRef?.current,
    threshold: 1,
  });

  const [currentLayer, setCurrentLayer] =
    useCurrentLayerSelector();
  return (
    <div
      ref={ref}
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        transition: "all 0.1s ease-in",
        opacity: entry?.isIntersecting ? 1 : 0,
      }}
    >
      {/* {isTooltip ? <Tooltip /> : <></>} */}

      <span
        style={{
          position: "absolute",
          left: 30,
          transition: "all 0.1s ease-in",
          opacity: currentLayer === id ? 1 : 0,
        }}
      >
        <RightArrowIcon />
      </span>

      <div
        className={`swatchCircle${
          currentLayer === id
            ? " swatchCircleActive"
            : ""
        }`}
        style={{
          backgroundColor: color,
        }}
        onClick={() => setCurrentLayer(id)}
      />
      {/* <Tooltip
        width="36px"
        height="64px"
        top="0px"
        left="5px"
        arrow_display="none"
      >
        <span style={{ marginLeft: "5px", marginTop: "4px" }}>
          <ColorPickIcon />
        </span>
        <span style={{ marginLeft: "5px", marginTop: "4px" }}>
          <ColorBucketIcon />
        </span>
      </Tooltip> */}
    </div>
  );
};

export default SwatchItem;
