import React from "react";

const RightArrowIcon = () => {
  return (
    <>
      <svg
        width="6"
        height="10"
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-2.18557e-07 5L6 0.669872L6 9.33013L-2.18557e-07 5Z"
          fill="#303030"
        />
      </svg>
    </>
  );
};

export default RightArrowIcon;
