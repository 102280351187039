import React from "react";

const FilterIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H16H0ZM16 16H0H16Z" fill="#303030" />
      <path
        d="M2.83345 3.73999C4.38012 5.72666 6.66678 8.66666 6.66678 8.66666V12C6.66678 12.7333 7.26678 13.3333 8.00012 13.3333C8.73345 13.3333 9.33345 12.7333 9.33345 12V8.66666C9.33345 8.66666 11.6201 5.72666 13.1668 3.73999C13.5068 3.29999 13.1935 2.66666 12.6335 2.66666H3.36012C2.80679 2.66666 2.49345 3.29999 2.83345 3.73999Z"
        fill="#676767"
      />
    </svg>
  );
};

export default FilterIcon;
