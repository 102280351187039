import React from "react";

const RedoIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4284 10.6C16.5784 8.99 14.1784 8 11.5284 8C7.36836 8 3.78836 10.42 2.08836 13.93C1.76836 14.6 2.12836 15.4 2.83836 15.64C3.42836 15.84 4.06836 15.56 4.33836 15C5.63836 12.34 8.36836 10.5 11.5284 10.5C13.4784 10.5 15.2584 11.22 16.6484 12.38L14.7384 14.29C14.1084 14.92 14.5484 16 15.4384 16H21.0284C21.5784 16 22.0284 15.55 22.0284 15V9.41C22.0284 8.52 20.9484 8.07 20.3184 8.7L18.4284 10.6Z"
        fill="#676767"
      />
    </svg>
  );
};

export default RedoIcon;
