import React, {
  useEffect,
  useState,
} from "react";
import useImage from "use-image";
// import Jimp from "jimp/browser/lib/jimp";

const useRecolorImage = (
  url: string,
  color: { r: number; g: number; b: number }
) => {
  // const [_color, setColor] = useState(color);
  // const [imgObj, setImgObj] = useState<Jimp>(
  //   null as any
  // );
  const [originalImg, imageLoadingStatus] =
    useImage(url, "anonymous");
  const [recolorImage, setRecolorImage] =
    useState<HTMLImageElement>();
  const [status, setStatus] = useState<
    "loading" | "done" | "failed"
  >("loading");
  useEffect(() => {
    if (
      imageLoadingStatus === "loaded" &&
      status === "loading"
    ) {
      setRecolorImage(originalImg);
      setStatus("done");
      // Jimp.read(originalImg!.src)
      //   .then((imgObj) => {
      //     setImgObj(imgObj);
      //     imgObj.scan(
      //       0,
      //       0,
      //       imgObj.bitmap.width,
      //       imgObj.bitmap.height,
      //       (x, y, idx) => {
      //         imgObj.bitmap.data[idx + 0] =
      //           color.r;
      //         imgObj.bitmap.data[idx + 1] =
      //           color.g;
      //         imgObj.bitmap.data[idx + 2] =
      //           color.b;
      //       }
      //     );
      //     imgObj
      //       .getBase64Async(Jimp.MIME_PNG)
      //       .then((base64) => {
      //         var img =
      //           document.createElement("img");
      //         img.src = base64;

      //         setRecolorImage(img);
      //         setStatus("done");
      //       });
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //     setStatus("failed");
      //   });
    }
  }, [
    status,
    originalImg,
    imageLoadingStatus,
    color,
  ]);
  // useEffect(() => {
  //   if (
  //     status === "done" &&
  //     (_color.r !== color.r ||
  //       _color.g !== color.g ||
  //       _color.b !== color.b) &&
  //     imgObj
  //   ) {
  //     imgObj.scan(
  //       0,
  //       0,
  //       imgObj.bitmap.width,
  //       imgObj.bitmap.height,
  //       (x, y, idx) => {
  //         imgObj.bitmap.data[idx + 0] = color.r;
  //         imgObj.bitmap.data[idx + 1] = color.g;
  //         imgObj.bitmap.data[idx + 2] = color.b;
  //       }
  //     );
  //     imgObj
  //       .getBase64Async(Jimp.MIME_PNG)
  //       .then((base64) => {
  //         var img = document.createElement("img");
  //         img.src = base64;

  //         setRecolorImage(img);
  //         setColor(color);
  //         console.log(color, _color, "done");
  //       });
  //   }
  // }, [
  //   _color,
  //   color,
  //   imgObj,
  //   originalImg,
  //   status,
  // ]);

  return {
    status,
    recolorImage,
    imageLoadingStatus,
    originalImg,
  };
};

export default useRecolorImage;
