import React from "react";

const EditIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2574 4.6927C17.5824 5.0177 17.5824 5.5427 17.2574 5.8677L15.7324 7.3927L12.6074 4.2677L14.1324 2.7427C14.288 2.58666 14.4994 2.49896 14.7199 2.49896C14.9403 2.49896 15.1517 2.58666 15.3074 2.7427L17.2574 4.6927ZM2.49902 17.0844V14.551C2.49902 14.4344 2.54069 14.3344 2.62402 14.251L11.7157 5.15937L14.8407 8.28437L5.74069 17.376C5.66569 17.4594 5.55736 17.501 5.44902 17.501H2.91569C2.68236 17.501 2.49902 17.3177 2.49902 17.0844Z"
        fill="#676767"
      />
    </svg>
  );
};

export default EditIcon;
