import useScreenSize from "@tinyd/usescreensize";
import React, { FC } from "react";
import { Layer, Stage, Image } from "react-konva";
import { useRecolor } from "../../services/RecolorLayer/RecolorLayer";
import LayerImg from "./LayerImg";
const RecolorCanvas: FC = () => {
  const { height, width } = useScreenSize();

  const recolor = useRecolor();
  return (
    <div>
      <Stage width={width} height={height}>
        <Layer>
          {recolor.layers.map((img, i) => {
            return (
              <LayerImg
                recolor={recolor}
                src={img}
                key={i}
                id={i}
                hexColor={
                  recolor.currentColors[i] ||
                  "#FF0000"
                }
              />
            );
          })}
        </Layer>
      </Stage>
    </div>
  );
};

export default RecolorCanvas;
