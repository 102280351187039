import React from "react";

const UndoIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4658 8C9.81582 8 7.41582 8.99 5.56582 10.6L3.67582 8.71C3.04582 8.08 1.96582 8.52 1.96582 9.41V15C1.96582 15.55 2.41582 16 2.96582 16H8.55582C9.44582 16 9.89582 14.92 9.26582 14.29L7.35582 12.38C8.74582 11.22 10.5158 10.5 12.4758 10.5C15.6358 10.5 18.3658 12.34 19.6658 15C19.9358 15.56 20.5758 15.84 21.1658 15.64C21.8758 15.41 22.2358 14.6 21.9158 13.92C20.1958 10.42 16.6158 8 12.4658 8Z"
        fill="#676767"
      />
    </svg>
  );
};

export default UndoIcon;
