export const IS_DEV = window.location.host.includes(
  "localhost"
)
  ? true
  : window.location.host.includes("-dev")
  ? true
  : false;

const ENDPOINT_URL = IS_DEV
    ? "https://recolor-api-dev.rmcoco.com"
    : "https://recolor-api.rmcoco.com";
    
export const GQL_URL = ENDPOINT_URL + "/api/public/graphql";

export const USER_AUTH_URL = IS_DEV
  ? "https://recolor-api-dev.rmcoco.com/recolor-session"
  : "https://recolor-api.rmcoco.com/recolor-session";
