import { useCallback, useState } from "react";
import {
  UserType,
  useSaveUserColorPaletteMutation,
} from "./graphql/graphql";

const useSaveMyColors = () => {
  const [doSaveColors] =
    useSaveUserColorPaletteMutation();
  const [isSaving, setSaving] = useState(false);
  const [isSaved, setSaved] = useState(false);
  const onSave = useCallback(
    (colorList: string[], name: string) => {
      if (isSaving) {
        console.log("already saving colors");
        return;
      }
      setSaving(true);
      doSaveColors({
        variables: {
          data: {
            color_palette_name: name,
            user_type: UserType.User,
            colors: {
              set: colorList,
            },
          },
        },
      })
        .then((res) => {
          console.log(res);
          setSaved(true);
          setTimeout(() => {
            console.log("done");
            setSaved(false);
          }, 3000);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [doSaveColors, isSaving]
  );
  return {
    isSaving,
    isSaved,
    onSave,
  };
};

export default useSaveMyColors;
