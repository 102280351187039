import React, { FC } from "react";
import { styled } from "@stitches/react";

const Container = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px",
  position: "fixed",
  width: "100vw",
  height: "100vh",
  background: " rgba(48, 48, 48, 0.8)",
  zIndex: "9999",
  backdropFilter: "blur(10px)",
});
const Modal = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  width: "512px",
  height: "184px",
  padding: "24px",

  backgroundColor: "white",
  position: "relative",
});
const ModalContainer = styled("div", {
  padding: "0px 70px 0px ",
});
const Title = styled("p", {
  width: "512px",
  height: "40px",
  fontFamily: "Spartan",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "30px",
  lineHeight: "40px",
  margin: "0px",
  marginBottom: "16px",
});

const Content = styled("p", {
  width: "512px",
  height: "72px",
  fontFamily: "Spartan",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  marginTop: "0px",
});

const ButtonContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "319px",
  height: "40px",
  padding: "0px",
  position: "relative",
  gap: "16px",
});

const Button = styled("button", {
  width: "130px",
  height: "40px",
  padding: "11px 10px 9px 10px",

  fontFamily: "Spartan",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  cursor: "pointer",
  border: "none",
  "&:active,&:hover,&:focus": {
    background: "#303030",
    color: "#ffffff",
  },
});
const CancelModal: FC<{
  onCancel: () => void;
  onYes: () => void;
  text: React.ReactNode;
}> = ({ onCancel, onYes, text }) => {
  return (
    <Container>
      <Modal>
        {/* <Title>Are you sure?</Title> */}
        <ModalContainer>
          <Content>{text}</Content>
          <ButtonContainer>
            <Button onClick={onYes}>
              Yes, I'm sure.
            </Button>
            <Button
              style={{
                width: "200px",
                padding: "11px 1px 9px",
              }}
              onClick={onCancel}
            >
              No, take me back.
            </Button>
          </ButtonContainer>
        </ModalContainer>
      </Modal>
    </Container>
  );
};

export default CancelModal;
