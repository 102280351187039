import React, {
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { styled } from "@stitches/react";
import Ruler from "@scena/react-ruler";
import "../../index.css";
import { useRecolorPreview } from "../../services/RecolorLayer/RecolorLayer";
import TypographySmall from "../DS/Typography";

const StyledRuler = styled("div", {
  position: "sticky",
  top: 0,
  left: 24,
  height: 24,
  width: "calc(100vw - 24px)",
  backdropFilter: "blur(5px)",
  transition: "all 0.1s ease-in",
});
const StyledVerticalRuler = styled("div", {
  position: "sticky",
  top: 24,
  left: 0,
  height: "calc(100vh - 24px)",
  width: 24,
  backdropFilter: "blur(5px)",
  transition: "all 0.1s ease-in",
});

const HorizontalRuler = () => {
  const { isPreviewMode } = useRecolorPreview();
  const ref = useRef<Ruler>(null);
  const [width, setWidth] = useState(
    window.innerWidth - 24
  );
  const unit = useMemo(() => {
    if (width < 700) {
      return 5;
    } else if (width < 900) {
      return 4;
    } else if (width < 1300) {
      return 3;
    }

    return 2;
  }, [width]);
  useEffect(() => {
    const fn = () => {
      ref.current?.resize();
      // console.log("resize", ref);
      setWidth(window.innerWidth - 24);
    };
    window.addEventListener("resize", fn);
    return () =>
      window.removeEventListener("resize", fn);
  }, [ref]);
  return (
    <StyledRuler
      className={
        isPreviewMode ? "previewModeRulerTop" : ""
      }
    >
      <Ruler
        ref={ref}
        type="horizontal"
        height={24}
        width={width}
        unit={unit}
        mainLineSize={24}
        longLineSize={6}
        shortLineSize={3}
        textAlign="left"
        direction="start"
        textOffset={[0, -4]}
        backgroundColor="rgba(255, 255, 255, 0.8)"
        textColor="#303030"
        style={{
          width,
          height: 24,
        }}
        zoom={width / 54}
        lineColor="#c4c4c4"
      />
    </StyledRuler>
  );
};
const VerticalRuler = () => {
  const { isPreviewMode } = useRecolorPreview();
  const ref = useRef<Ruler>(null);
  const [width, setWidth] = useState(
    window.innerWidth - 24
  );
  const [height, setHeight] = useState(
    window.innerHeight
  );
  const unit = useMemo(() => {
    if (width < 700) {
      return 5;
    } else if (width < 900) {
      return 4;
    } else if (width < 1300) {
      return 3;
    }

    return 2;
  }, [width]);
  useEffect(() => {
    const fn = () => {
      ref.current?.resize();
      // console.log("resize", ref);
      setWidth(window.innerWidth - 24);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", fn);
    return () =>
      window.removeEventListener("resize", fn);
  }, [ref]);
  return (
    <StyledVerticalRuler
      className={
        isPreviewMode
          ? "previewModeRulerLeft"
          : ""
      }
    >
      <Ruler
        ref={ref}
        type="vertical"
        height={height}
        width={24}
        unit={unit}
        mainLineSize={24}
        longLineSize={6}
        shortLineSize={3}
        textAlign="right"
        direction="start"
        textOffset={[-4, 0]}
        backgroundColor="rgba(255, 255, 255, 0.8)"
        textColor="#303030"
        style={{
          width: 24,
          height,
        }}
        zoom={width / 54}
        lineColor="#c4c4c4"
      />
    </StyledVerticalRuler>
  );
};

const UnitContainerStyled = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  width: 24,
  height: 24,
  backdropFilter: "blur(5px)",
  transition: "all 0.1s ease-in",
});

const UnitContainer: FC = () => {
  const { isPreviewMode } = useRecolorPreview();
  return (
    <UnitContainerStyled
      className={
        isPreviewMode ? "previewMode" : ""
      }
    >
      <div
        style={{
          width: 24,
          height: 24,
          backgroundColor:
            "rgba(255, 255, 255, 0.8)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 2,
        }}
      >
        <TypographySmall>IN</TypographySmall>
      </div>
    </UnitContainerStyled>
  );
};

const Rulers: FC = () => {
  return (
    <>
      <UnitContainer />
      <HorizontalRuler />
      <VerticalRuler />
    </>
  );
};

export default Rulers;
