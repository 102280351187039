import { styled } from "@stitches/react";
import React, { FC } from "react";

const StyledButton = styled("button", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minWidth: "40px",
  height: "40px",
  background: " #FFFFFF",
  boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.2)",
  borderColor: "transparent",
  borderRadius: " 3px",
  paddingLeft: 16,
  paddingRight: 16,
  cursor: "pointer",
  fontFamily: "Spartan",
  fontSize: 16,
  fontWeight: "normal",
  backdropFilter: "blur(2px)",
  transition: "all 0.1s ease-in",
  "&:hover": {
    background: "#ececec",
  },
  "&:focus": {
    outline: "none",
    background: "#ececec",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px rgba(0, 0, 0, 0.1);",
  },
  "&:active": {
    background: "#D2D2D2",
  },
  "&:disabled": {
    background: "rgba(200,200, 200, 0.7)",
    cursor: "not-allowed",
    "& > *": {
      mixBlendMode: "multiply",
    },
  },
});
const Button = StyledButton;

export default Button;
