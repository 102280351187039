import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { client } from "./services/apollo";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import TokenHandshake from "./components/TokenHandshake/TokenHandshake";

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <TokenHandshake>
        <Routes>
          <Route
            path=":productId"
            element={<App isNew={true} />}
          />
          <Route
            path=":productId/:recolorId"
            element={<App isNew={false} />}
          />
          <Route
            path="*"
            element={<div>not found</div>}
          ></Route>
        </Routes>
      </TokenHandshake>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
