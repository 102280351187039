import React, {
  FC,
  useCallback,
  useMemo,
  useState,
} from "react";
import { styled } from "../../stitchesConfig";
import TypographySmall, {
  TypographyMedium,
  TypographyMedium2,
} from "../DS/Typography";
import CancelIcon from "../Icons/CancelIcon";

import DownArrowIcon from "../Icons/DownArrowIcon";
import { useSecureApiServiceQuery } from "../../hooks/graphql/graphql";
import { getUserId } from "../../services/TokenHelper";
import { useRecolor } from "../../services/RecolorLayer/RecolorLayer";

const Container = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px",
  position: "fixed",
  width: "100vw",
  height: "100vh",
  background: " rgba(48, 48, 48, 0.8)",
  zIndex: "9999",
  backdropFilter: "blur(10px)",
});
const Modal = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  // width: "512px",
  // height: "184px",
  minWidth: 380,

  padding: "24px",

  backgroundColor: "white",
  position: "relative",
});
const ModalContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  width: "100%",
});

const StyledInput = styled("input", {
  boxSizing: "border-box",
  padding: "0.5em 0.571em 0.357em",
  width: "100%",
  height: "32px",
  background: "#FAFAFA",
  border: "1px solid #D2D2D2",
  borderRadius: "3px",
  fontFamily: "Spartan",
  fontWeight: 500,
  outline: "none",
});
const StyledSelect = styled("select", {
  boxSizing: "border-box",
  padding: "0.5em 0.571em 0.357em",
  width: "100%",
  height: "32px",
  background: "#FAFAFA",
  border: "1px solid #D2D2D2",
  borderRadius: "3px",
  fontFamily: "Spartan",
  fontWeight: 500,
  outline: "none",
});

const Button = styled("button", {
  height: "40px",
  padding: "11px 10px 9px 10px",

  fontFamily: "Spartan",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  cursor: "pointer",
  border: "none",
  "&:active,&:hover,&:focus": {
    background: "#303030",
    color: "#ffffff",
  },
});

const SaveModal: FC<{
  onCancel: () => void;
  onYes: (name: string, id: string) => void;
}> = ({ onCancel, onYes }) => {
  const recolor = useRecolor();
  const [name, setName] = useState("");
  const [clientId, setClientId] = useState(-1);
  const { data, loading } = useSecureApiServiceQuery({
    variables: {
      route: `project/clients-auth/${getUserId()}`,
    },
  });
  const fetchAPI = useSecureApiServiceQuery({
    skip: true,
  });
  const clients = useMemo<
    { id: number; name: string }[]
  >(() => {
    if (!loading && data && data.secureAPIService) {
      const _d = JSON.parse(data.secureAPIService);
      console.log(_d);
      if (_d && _d.status) {
        return _d.data.map((d: any) => ({
          id: d.value,
          name: d.text,
        }));
      }
    }
    return [];
  }, [data, loading]);
  const [step, setStep] = useState(0);
  const [mode, setMode] = useState(0);
  const onClose = useCallback(() => {
    setStep(0);
    setMode(0);
    onCancel();
  }, [onCancel]);
  const goToNext = useCallback((mode: number) => {
    setMode(mode);
    setStep(1);
  }, []);
  const [projects, setProjects] = useState<
    { id: number; name: string }[]
  >([]);
  const [projectId, setProjectId] = useState(-1);
  const [projectName, setProjectName] = useState("");
  const [clientName, setClientName] = useState("");
  const [projectLoading, setProjectLoading] =
    useState(false);
  const [projectLoaded, setProjectLoaded] = useState(false);
  const loadProjects = useCallback(
    (clientId: number) => {
      setProjects([]);
      setProjectLoaded(false);
      setProjectLoading(true);
      setProjectId(-1);
      fetchAPI
        .refetch({
          route: `projects-auth/${clientId}`,
        })
        .then((res) => {
          if (
            res &&
            res.data &&
            res.data.secureAPIService
          ) {
            const _d = JSON.parse(
              res.data.secureAPIService
            );
            console.log(_d);
            if (_d && _d.status) {
              setProjects(
                _d.data.map((d: any) => ({
                  id: d.value,
                  name: d.text,
                }))
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Failed to fetch projects");
        })
        .finally(() => {
          setProjectLoaded(true);
          setProjectLoading(false);
        });
      setClientId(clientId);
    },
    [fetchAPI]
  );
  const [isCreating, setIsCreating] = useState(false);

  const doSave = useCallback(() => {
    const _p = new URLSearchParams(
      (window as any).location.search
    );
    if (isCreating) {
      return;
    }
    if (mode === 0) {
      if (clientId === -1) {
        alert("Please select a client");
        return;
      }
      if (projectId === -1) {
        alert("Please select a project");
        return;
      }
      if (name.length === 0) {
        alert("Please enter design name");
        return;
      }
      setIsCreating(true);
      recolor.setRedirectConfig(`${clientId}` || "null");
      fetchAPI
        .refetch({
          route: "project/addproducttoproject-auth",
          data: JSON.stringify({
            basefabricname: _p.get("basefabricname"),
            cart: "",
            client: `${clientId}`,
            error_name: "",
            inventcolorid: "99",
            inventstyleid: _p.get("inventstyleid"),
            notes: "",
            order_type: _p.get("order_type"),
            pc_type: "Fabric",
            product_id: recolor.productId,
            project: `${projectId}`,
            quantity: _p.get("quantity"),
            recolor_id: recolor.assetId,
            user_id: "",
          }),
          method: "POST",
        })
        .then((res) => {
          if (
            res &&
            res.data &&
            res.data.secureAPIService
          ) {
            const _d = JSON.parse(
              res.data.secureAPIService
            );
            if (_d && _d.status) {
              if (_d.data?.error) {
                alert(
                  _d.data?.error ||
                    "Failed to save to projects"
                );
              } else {
                onYes(name, `${clientId}`);
              }
            } else {
              alert("Failed to save to projects");
            }
            console.log(_d);
          } else {
            alert("Failed to save to projects");
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Failed to save to projects");
        })
        .finally(() => {
          setIsCreating(false);
        });
    } else if (mode === 1) {
      if (clientId === -1) {
        alert("Please select a client");
        return;
      }
      if (projectName.length === 0) {
        alert("Please enter project name");
        return;
      }
      if (name.length === 0) {
        alert("Please enter design name");
        return;
      }

      setIsCreating(true);
      recolor.setRedirectConfig(`${clientId}` || "");
      fetchAPI
        .refetch({
          route: "project/addproject-auth",
          data: JSON.stringify({
            basefabricname: _p.get("basefabricname"),
            cart: "",
            client: `${clientId}`,
            error_name: "",
            inventcolorid: "99",
            inventstyleid: _p.get("inventstyleid"),
            name: `${projectName}`,
            notes: "",
            order_type: _p.get("order_type"),
            pc_type: "Fabric",
            product_id: recolor.productId,
            quantity: _p.get("quantity"),
            recolor_id: recolor.assetId,
            user_id: "",
          }),
          method: "POST",
        })
        .then((res) => {
          if (
            res &&
            res.data &&
            res.data.secureAPIService
          ) {
            const _d = JSON.parse(
              res.data.secureAPIService
            );
            if (_d && _d.status) {
              if (_d.data?.error) {
                alert(
                  _d.data?.error ||
                    "Failed to save to projects"
                );
              } else {
                onYes(name, `${clientId}`);
              }
            } else {
              alert("Failed to save to projects");
            }
            console.log(_d);
          } else {
            alert("Failed to save to projects");
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Failed to save to projects");
        })
        .finally(() => {
          setIsCreating(false);
        });
    } else if (mode === 2) {
      if (clientName.length === 0) {
        alert("Please enter client name");
        return;
      }
      if (projectName.length === 0) {
        alert("Please enter project name");
        return;
      }
      if (name.length === 0) {
        alert("Please enter design name");
        return;
      }

      setIsCreating(true);
      fetchAPI
        .refetch({
          route: "project/addclientandproject-auth",
          data: JSON.stringify({
            basefabricname: _p.get("basefabricname"),
            cart: "",
            createclient: `${clientName}`,
            createproject: `${projectName}`,
            error_name: "",
            inventcolorid: "99",
            inventstyleid: _p.get("inventstyleid"),
            notes: "",
            order_type: _p.get("order_type"),
            pc_type: "Fabric",
            product_id: recolor.productId,
            quantity: _p.get("quantity"),
            recolor_id: recolor.assetId,
            user_id: getUserId(),
          }),
          method: "POST",
        })
        .then((res) => {
          if (
            res &&
            res.data &&
            res.data.secureAPIService
          ) {
            const _d = JSON.parse(
              res.data.secureAPIService
            );
            if (_d && _d.status) {
              if (_d.data?.error) {
                alert(
                  _d.data?.error ||
                    "Failed to save to projects"
                );
              } else {
                console.log(
                  _d.data?.success?.client_id || "",
                  "client id"
                );
                recolor.setRedirectConfig(
                  `${_d.data?.success?.client_id}` || ""
                );

                onYes(
                  name,
                  `${_d.data?.success?.client_id}`
                );
              }
            } else {
              alert("Failed to save to projects");
            }
            console.log(_d);
          } else {
            alert("Failed to save to projects");
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Failed to save to projects");
        })
        .finally(() => {
          setIsCreating(false);
        });
    }
  }, [
    isCreating,
    mode,
    clientId,
    projectId,
    name,
    fetchAPI,
    recolor,
    onYes,
    projectName,
    clientName,
  ]);
  return (
    <Container>
      <Modal>
        <ModalContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: 12,
            }}
          >
            <TypographyMedium>
              Save To Projects
            </TypographyMedium>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={onClose}
            >
              <CancelIcon color={"#aaa"} />
            </div>
          </div>
          {step === 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 16,
                  paddingTop: 8,
                  paddingBottom: 8,
                  cursor: "pointer",
                }}
                onClick={() => {
                  goToNext(0);
                }}
              >
                <TypographyMedium2>
                  Existing Client - Existing Project
                </TypographyMedium2>
                <div
                  style={{ transform: "rotateZ(-90deg)" }}
                >
                  <DownArrowIcon />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 16,
                  paddingTop: 8,
                  paddingBottom: 8,
                  borderTop: "1px solid #cccc",
                  borderBottom: "1px solid #cccc",
                  cursor: "pointer",
                }}
                onClick={() => {
                  goToNext(1);
                }}
              >
                <TypographyMedium2>
                  Existing Client - New Project
                </TypographyMedium2>
                <div
                  style={{ transform: "rotateZ(-90deg)" }}
                >
                  <DownArrowIcon />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 16,
                  paddingTop: 8,
                  paddingBottom: 8,
                  cursor: "pointer",
                }}
                onClick={() => {
                  goToNext(2);
                }}
              >
                <TypographyMedium2>
                  New Client - New Project
                </TypographyMedium2>
                <div
                  style={{ transform: "rotateZ(-90deg)" }}
                >
                  <DownArrowIcon />
                </div>
              </div>
            </>
          ) : null}
          {step === 1 ? (
            <>
              {mode === 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                    }}
                  >
                    <TypographySmall>
                      Select Client
                    </TypographySmall>
                    {loading ? (
                      <TypographySmall>
                        Please wait...
                      </TypographySmall>
                    ) : null}
                    <StyledSelect
                      disabled={isCreating}
                      defaultValue={clientId.toString()}
                      onChange={(e) => {
                        if (e.target.value !== "-1")
                          loadProjects(
                            parseInt(e.target.value)
                          );
                        else {
                          setProjectLoaded(false);
                          setClientId(-1);
                        }
                      }}
                    >
                      <option value="-1">--</option>
                      {clients.map((d) => (
                        <option key={d.id} value={d.id}>
                          {d.name}
                        </option>
                      ))}
                    </StyledSelect>
                  </div>
                  {projectLoading ? (
                    <TypographySmall>
                      Please Wait...
                    </TypographySmall>
                  ) : null}
                  {projectLoaded ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 8,
                          marginTop: 8,
                        }}
                      >
                        <TypographySmall>
                          Select Project
                        </TypographySmall>

                        <StyledSelect
                          disabled={isCreating}
                          defaultValue={projectId.toString()}
                          onChange={(e) => {
                            setProjectId(
                              parseInt(e.target.value)
                            );
                          }}
                        >
                          <option value="-1">--</option>

                          {projects.map((d) => (
                            <option key={d.id} value={d.id}>
                              {d.name}
                            </option>
                          ))}
                        </StyledSelect>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 8,
                          marginTop: 8,
                        }}
                      >
                        <TypographySmall>
                          Enter Design Name
                        </TypographySmall>
                        <StyledInput
                          disabled={isCreating}
                          defaultValue={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
              {mode === 1 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                    }}
                  >
                    <TypographySmall>
                      Select Client
                    </TypographySmall>
                    {loading ? (
                      <TypographySmall>
                        Please wait...
                      </TypographySmall>
                    ) : null}
                    <StyledSelect
                      disabled={isCreating}
                      defaultValue={clientId.toString()}
                      onChange={(e) => {
                        if (e.target.value !== "-1") {
                          setClientId(
                            parseInt(e.target.value)
                          );
                          setProjectLoaded(true);
                        } else {
                          setProjectLoaded(false);
                          setClientId(-1);
                        }
                      }}
                    >
                      <option value="-1">--</option>
                      {clients.map((d) => (
                        <option key={d.id} value={d.id}>
                          {d.name}
                        </option>
                      ))}
                    </StyledSelect>
                  </div>

                  {projectLoaded ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 8,
                          marginTop: 8,
                        }}
                      >
                        <TypographySmall>
                          Create and save to new project
                        </TypographySmall>

                        <StyledInput
                          disabled={isCreating}
                          defaultValue={projectName}
                          onChange={(e) => {
                            setProjectName(e.target.value);
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 8,
                          marginTop: 8,
                        }}
                      >
                        <TypographySmall>
                          Enter Design Name
                        </TypographySmall>
                        <StyledInput
                          disabled={isCreating}
                          defaultValue={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
              {mode === 2 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                    }}
                  >
                    <TypographySmall>
                      Create a new Client
                    </TypographySmall>

                    <StyledInput
                      disabled={isCreating}
                      defaultValue={clientName}
                      onChange={(e) => {
                        setClientName(e.target.value);
                      }}
                    />
                  </div>

                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                        marginTop: 8,
                      }}
                    >
                      <TypographySmall>
                        Create a new project
                      </TypographySmall>

                      <StyledInput
                        disabled={isCreating}
                        defaultValue={projectName}
                        onChange={(e) => {
                          setProjectName(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                        marginTop: 8,
                      }}
                    >
                      <TypographySmall>
                        Enter Design Name
                      </TypographySmall>
                      <StyledInput
                        disabled={isCreating}
                        defaultValue={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                  </>
                </>
              ) : null}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: 12,
                }}
              >
                <Button
                  onClick={() => {
                    if (isCreating) {
                      return;
                    }
                    setStep(0);
                    setClientId(-1);
                    setProjectId(-1);
                    setProjectName("");
                    setClientName("");
                    setProjectLoaded(false);
                  }}
                >
                  Go Back
                </Button>

                <Button onClick={doSave}>
                  {isCreating ? "Please wait..." : "Save"}
                </Button>
              </div>
            </>
          ) : null}
        </ModalContainer>
      </Modal>
    </Container>
  );
};

export default SaveModal;
