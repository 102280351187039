import React, {
  FC,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import SplashLoader from "../SplashLoader/SplashLoader";
import axios from "axios";
import { USER_AUTH_URL } from "../../config/global";
const TokenHandshake: FC = ({ children }) => {
  const [searchParams] = useSearchParams();

  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    if (isLoading) {
      axios
        .get(USER_AUTH_URL, {
          withCredentials: true,
          headers: {
            Authorization:
              searchParams.get("token") || "",
          },
        })
        .then((response) => {
          console.log("USER AUTH", response);
        })
        .catch((error) => {
          console.log("USER AUTH ERROR", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isLoading, searchParams]);

  if (isLoading) {
    return (
      <SplashLoader text="Loading User Details..." />
    );
  }

  return <>{children}</>;
};

export default TokenHandshake;
