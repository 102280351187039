import React, { FC } from "react";
import { styled } from "../../stitchesConfig";
import Tooltip from "../Tooltip/Tooltip";
import { useState } from "react";
import Button from "./Button";

const ButtonWithIcon: FC<{
  onClick?: () => void;
  className?: string;
  tooltip: any;
  disabled?: boolean;
}> = ({
  children,
  onClick,
  tooltip,
  disabled = false,
  className,
}) => {
  return (
    <Tooltip text={tooltip}>
      <Button
        className={className}
        disabled={disabled}
        onClick={onClick}
        style={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

export default ButtonWithIcon;
