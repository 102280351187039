import { CSSProperties, styled } from "@stitches/react";
import React, { FC } from "react";
import ColorBucketIcon from "../Icons/ColorBucketIcon";
import ColorPickIcon from "../Icons/ColorPickIcon";
import { TypographyMedium2 } from "../DS/Typography";

const StyledToolTip = styled("span", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: `8px`,

  position: "absolute",
  // width: `${width}`,
  // height: `${height}`,

  // top: `${top}`,
  // left: `${left}`,
  top: `calc(100% + 5px)`,
  left: 0,

  textAlign: "center",

  color: "#FFFFFF",
  background: "#303030",
  borderRadius: "3px",

  zIndex: "10",

  flex: "none",
  order: 0,
  flexGrow: 1,

  filter: "dropShadow(0px 4px 8px rgba(0, 0, 0, 0.1))",
  whiteSpace: "pre",
});

const StyledArrow = styled("div", {
  position: "absolute",
  left: "calc(50% - 0.426em / 2)",
  // top: `${arrow_top}`,
  // left: `${arrow_left}`,
  // display: `${arrow_display}`,

  width: "0",
  height: "0",

  borderLeft: "0.426em solid transparent",
  borderRight: "0.426em solid transparent",
  borderBottom: "0.857em solid #303030",

  flex: "none",
  order: 0,
  flexGrow: 0,
});

const TooltipContainer = styled("div", {
  position: "relative",
  ".tooltip-el": {
    display: "none",
  },
  "&:hover": {
    ".tooltip-el": {
      display: "flex",
    },
  },
});

const Tooltip: FC<{
  text: string;
  style?: CSSProperties;
  isRight?: boolean;
}> = ({ children, style, text, isRight }) => {
  return (
    <TooltipContainer style={style}>
      {children}
      <div
        style={{
          width: "100%",
          position: "absolute",
          bottom: 0,
        }}
      >
        <StyledArrow className="tooltip-el" />

        <StyledToolTip
          className="tooltip-el"
          style={
            isRight
              ? {
                  right: 0,
                  left: "auto",
                }
              : {}
          }
        >
          <TypographyMedium2 color="#ffffff">
            {text}
          </TypographyMedium2>
        </StyledToolTip>
      </div>
    </TooltipContainer>
  );
};

export default Tooltip;
