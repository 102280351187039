import { styled } from "@stitches/react";
import React, { FC } from "react";

const TypographySmall: FC<{ color?: string }> = ({
  children,
  color = "#303030",
}) => {
  const BodySmall = styled(`div`, {
    fontFamily: "Spartan",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    color: `${color}`,
  });
  return <BodySmall>{children}</BodySmall>;
};
export const TypographyMedium: FC<{ color?: string }> = ({
  children,
  color = "#303030",
}) => {
  const BodyMedium = styled(`div`, {
    fontFamily: "Spartan",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: `${color}`,
  });
  return <BodyMedium>{children}</BodyMedium>;
};
export const TypographyMedium2: FC<{ color?: string }> = ({
  children,
  color = "#303030",
}) => {
  const BodyMedium2 = styled(`div`, {
    fontFamily: "Spartan",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: `${color}`,
  });
  return <BodyMedium2>{children}</BodyMedium2>;
};

export default TypographySmall;
