import React from "react";

const AddIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
        fill="#676767"
      />
      <path
        d="M3 1H21V-1H3V1ZM23 3V21H25V3H23ZM21 23H3V25H21V23ZM1 21V3H-1V21H1ZM3 23C1.89543 23 1 22.1046 1 21H-1C-1 23.2091 0.790861 25 3 25V23ZM23 21C23 22.1046 22.1046 23 21 23V25C23.2091 25 25 23.2091 25 21H23ZM21 1C22.1046 1 23 1.89543 23 3H25C25 0.790861 23.2091 -1 21 -1V1ZM3 -1C0.790861 -1 -1 0.790861 -1 3H1C1 1.89543 1.89543 1 3 1V-1Z"
        fill="#303030"
      />
    </svg>
  );
};

export default AddIcon;
