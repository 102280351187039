import React from "react";

const UpArrowIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.12498 15L12.005 11.12L15.885 15C16.275 15.39 16.905 15.39 17.295 15C17.685 14.61 17.685 13.98 17.295 13.59L12.705 9.00001C12.5181 8.81275 12.2645 8.70752 12 8.70752C11.7355 8.70752 11.4818 8.81275 11.295 9.00001L6.70498 13.59C6.31498 13.98 6.31498 14.61 6.70498 15C7.09498 15.38 7.73498 15.39 8.12498 15Z"
          fill="#676767"
        />
      </svg>
    </>
  );
};

export default UpArrowIcon;
